import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./ColorVisionThemePage.css"
import colorPalette1 from "./../../Assets/Images/color_palette1.svg"
import colorPalette2 from "./../../Assets/Images/color-palette2.svg"
import colorPalette4 from "./../../Assets/Images/color-palette-4.svg"
import paletteImg from "./../../Assets/Images/pallete-img.png"
import paletteImg2 from "./../../Assets/Images/palette2.jpg"
import paletteImg3 from "./../../Assets/Images/palette3.jpg"
import Button from "react-bootstrap/Button"
import $ from "jquery"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { withRouter } from "react-router"
import BG from "./../../Assets/Images/Neutral-In-2019-3 .jpg"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import ScrollToTop from "../ScrollToTop"

import cogoToast from "cogo-toast"
import AOS from "aos"
import "aos/dist/aos.css"
import MetaWrapper from "../../MetaWrapper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import client from "../../Apollo/apolloClient"
import { REGIONS_LIST_QUERY } from "../../Apollo/queries/RegionListQuery"
import { GET_SINGLE_THEME_QUERY } from "../../Apollo/queries/GetSingleTheme"

class ColorVisionThemePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ColorTheme: [
        {
          color: "rgb(243, 128, 61)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(250, 198, 17)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(207, 64, 61)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(14, 143, 92)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(109, 166, 212)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(164, 144, 98)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgb(210, 196, 179)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
        {
          color: "rgba(234, 235, 237)",
          colorName: "White",
          colorCode: "NP OW 1001 P",
        },
      ],
      colorPalette: [
        { image: colorPalette1 },
        { image: colorPalette2 },
        { image: colorPalette4 },
      ],
      paletteImage: "",
      paletteImagesArray: [
        { image: paletteImg },
        { image: paletteImg2 },
        { image: paletteImg3 },
      ],
      selected_color: "",
      image_url: "",
      currentTheme: "",
      loadMsg: "",
      themeView: {},
      regionList: [],
      ThemeColorsArray: [],
      headerImageLeft: "",
      image_palete_value: 3,
      hide_button: false,
    }
    // this.getPaletteImg = this.getPaletteImg().
  }

  componentDidMount() {
    let iOS = false,
      p = navigator.platform
    let isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor)
    if (p === "iPad" || p === "iPhone" || p === "iPod" || isSafari === true) {
      iOS = true
    }
    if (iOS === true) {
      // $(".desktop_download_btn").hide();
      // $(".mobile_download_btn").hide();

      this.setState({
        hide_button: true,
      })
    }

    AOS.init()
    let theme = this.props.match.params.theme
    let themeId = parseInt(this.props.match.params.themeid)

    this.setState({
      currentTheme: theme,
    })

    const variables = {
      themeId: themeId,
    }

    let self = this
    client
      .query({
        query: GET_SINGLE_THEME_QUERY,
        variables: variables,
        errorPolicy: "ignore", // Set errorPolicy to 'ignore'
      })
      .then((response) => {
        const data = response.data
        if (data.singleThemeView !== null) {
          debugger
          let tempColorPalette = data.singleThemeView[0].colorsSet.edges
         

         
          // Create a copy using slice()
          const sortedColorTypes = tempColorPalette.slice().sort((a, b) => {
            return a.node.displayOrder - b.node.displayOrder;
          });
          
          // console.log(sortedColorTypes); // Sorted array
          // console.log(tempColorPalette); // Original array remains unchanged



          // console.log(tempColorPalette, "tempColorPalette")
          self.setState({
            sortedColorTypes: sortedColorTypes,
          })
          self.setState({
            themeView: data.singleThemeView[0],
            ThemeColorsArray: self.listToMatrix(
              sortedColorTypes,
              2
            ),
            headerImageLeft: data.singleThemeView[0].headerImageLeft,
            paletteImage: data.singleThemeView[0].colorPalette2
              ? data.singleThemeView[0].colorPalette2.paletteDisplayImageUrl
              : null,
            currentTheme: data.singleThemeView[0].headerImageRight,
          })
          console.log(
            self.state.ThemeColorsArray,
            data.singleThemeView[0].headerImageRight,
            "test"
          )
        } else {
          self.setState({
            loadMsg: "There is no data",
          })
          cogoToast.error("There is no data", { position: "top-center" })
        }
      })

    client
      .query({
        query: REGIONS_LIST_QUERY,
        variables: {},
      })
      .then((data) => {
        if (data.regionsList !== null) {
          this.setState({
            regionList: data.regionsList,
          })
        } else {
          cogoToast.error("There is no data", { position: "top-center" })
        }
      })
      .catch((error) => {
        console.error("Error fetching regions list:", error)
        cogoToast.error("Failed to fetch data", { position: "top-center" })
      })

    $("#section1").on("click", function (e) {
      e.preventDefault()
      $("html, body").animate(
        { scrollTop: $($(this).attr("href")).offset().top },
        500,
        "linear"
      )
    })

    $(".carousel").carousel({
      interval: false,
    })

    var left_wall = $(".left_wall")
    var left_wall_icon = $(".left_wall_icon")
    var right_wall = $(".right_wall")
    var right_wall_icon = $(".right_wall_icon")
    let scope = this
    left_wall.on("click", function () {
      scope.updateStateValues()
      for (let i = 0; i < left_wall.length; i++) {
        $(right_wall[i]).removeClass("on")
        if (left_wall[i].classList.contains("on")) {
          $(left_wall[i]).removeClass("on")
        } else {
          $(left_wall[i]).addClass("on")
        }
      }
    })
    left_wall_icon.on("click", function () {
      scope.updateStateValues()
      for (let i = 0; i < left_wall.length; i++) {
        $(right_wall[i]).removeClass("on")
        if (left_wall[i].classList.contains("on")) {
          $(left_wall[i]).removeClass("on")
        } else {
          $(left_wall[i]).addClass("on")
        }
      }
    })
    right_wall.on("click", function () {
      scope.updateStateValues()
      for (let i = 0; i < right_wall.length; i++) {
        $(left_wall[i]).removeClass("on")
        if (right_wall[i].classList.contains("on")) {
          $(right_wall[i]).removeClass("on")
        } else {
          $(right_wall[i]).addClass("on")
        }
      }
    })
    right_wall_icon.on("click", function () {
      scope.updateStateValues()
      for (let i = 0; i < right_wall.length; i++) {
        $(left_wall[i]).removeClass("on")
        if (right_wall[i].classList.contains("on")) {
          $(right_wall[i]).removeClass("on")
        } else {
          $(right_wall[i]).addClass("on")
        }
      }
    })

    this.toDataURL(BG)
  }
  listToMatrix = (list, elementsPerSubArray) => {
    var matrix = [],
      i,
      k

    for (i = 0, k = -1; i < list.length; i++) {
      if (i % elementsPerSubArray === 0) {
        k++
        matrix[k] = []
      }

      matrix[k][i] = list[i]
    }

    return matrix
  }

  changePalete = (imageurl, value) => {
    let image = imageurl
    this.setState({
      paletteImage: image,
      image_palete_value: value,
    })
  }
  updateStateValues = () => {
    this.setState({
      selected_color: "",
    })
  }

  fillColor = (color) => {
    let paths = document.getElementsByClassName("on")
    let selectedColor = paths.length > 0 ? this.getFormattedColor(color) : ""
    this.setState({
      selected_color: selectedColor,
    })

    for (let i = 0; i < paths.length; i++) {
      if (paths[i]) {
        if (paths[i].classList.contains("right_wall")) {
          for (var j = 0; j < paths[i].children.length; j++) {
            paths[i].children[j].style.fill = selectedColor
          }
        } else {
          paths[i].style.fill = selectedColor
        }
      }
    }
  }

  convertSvgToPng = () => {
    var canvas = document.getElementById("canvas")
    var ctx = canvas.getContext("2d")
    var data = new XMLSerializer().serializeToString(
      document.getElementsByClassName("user_not_view_svg")[0]
    )
    var DOMURL = window.URL || window.webkitURL || window
    var img = new Image()
    var svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" })
    var url = DOMURL.createObjectURL(svgBlob)
    var scope = this

    img.onload = function () {
      ctx.drawImage(img, 0, 0)
      DOMURL.revokeObjectURL(url)

      var imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")

      scope.triggerDownload(imgURI)
    }

    img.src = url
  }
  triggerDownload = (imgURI) => {
    var evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    })

    var a = document.createElement("a")
    a.setAttribute("download", "MyColorVision.png")
    a.setAttribute("href", imgURI)
    a.setAttribute("target", "_blank")

    a.dispatchEvent(evt)
  }

  toDataURL = (url) => {
    let scope = this
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        // return reader.result;
        scope.setState({
          image_url: reader.result,
        })
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
  }
  goToTheme = (e, regionid, region) => {
    this.props.history.push("/forecasts/" + region + "/" + regionid)
    // let regionList = this.state.regionList;
    // for (let i = 0; i < regionList.length; i++) {
    //     if (region === regionList[i].regionId) {
    //
    //         this.props.history.push('/themes/'+region, {region: regionList[i]});
    //
    //     } else {
    //
    //     }
    //
    // }
  }

  getFormattedColor = (color_code) => {
    var array = color_code.split(",").map(Number)
    let rgb = "rgb(" + array[0] + "," + array[1] + "," + array[2] + ")"

    return rgb
  }

  goToForcast = (e) => {
    this.props.history.push("/forecast-form")
  }

  render() {
    const { paletteImage, themeView, ThemeColorsArray } = this.state
    return (
      <MetaWrapper
        meta={{
          description: "Forecast " + this.props.match.params.theme + " theme",
          title: "Forecast " + this.props.match.params.theme + " theme",
        }}
      >
        <ScrollToTop />
        <div>
          <Container fluid={true} className="p-0">
            <Header />
            {/*<ForecastFormPage />*/}
            <div className="buttonDiv">
              <Button
                className="forecastformBtn"
                onClick={() => this.goToForcast()}
              >
                <FontAwesomeIcon
                  icon={faCloudDownloadAlt}
                  style={{ marginRight: "10px" }}
                />
                DOWNLOAD FORECAST 24-25
              </Button>
            </div>
            <Row className="ThemeHead">
              <Col xs={12} md={5} className="align-self-center order-xs-12">
                <div className="text-left my-auto p-5">
                  <img
                    className="img-fluid"
                    alt=""
                    src={
                      this.state.headerImageLeft
                        ? this.state.headerImageLeft
                        : null
                    }
                  />
                  <br />
                  <p className="AboutTheme  pt-sm-2 p-lg-5">
                    {themeView ? themeView.description : null}
                  </p>
                </div>
              </Col>
              <Col xs={12} md={7} className="p-0 order-xs-1">
                <img
                  className="img-fluid"
                  alt=""
                  src={this.state.currentTheme}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Row className="ThemeHead">
              {
                Object.keys(themeView).length > 0 &&
                  this.state.sortedColorTypes.length > 0 &&
                  this.state.sortedColorTypes.map((item, i) => {
                    return (
                      <Col
                        xs={12}
                        md={12}
                        key={i}
                        className="p-0"
                        data-aos="fade-right"
                        data-aos-delay={i * 300}
                      >
                        <div
                          className="pt-4 pb-4 pl-5 pr-5 colorTheme"
                          style={{
                            backgroundColor: this.getFormattedColor(
                              item.node.rgb
                            ),
                            opacity: "1",
                          }}
                        >
                          <p className="mb-0">{item.node.colorName}</p>
                          <p className="mb-0">{item.node.description}</p>
                        </div>
                      </Col>
                    )
                  })
                // :
                // <div className="empty_msg" style={{color: "#000"}}>{this.state.loadMsg}</div>
              }
            </Row>
            <div className="mt-3 mobile_palate">
              <p className="paletteHeadTitle">Curated Palette</p>
              {/*<p className="paletteHeadSubTitle">Click the palette to apply colors on the wall</p>*/}
            </div>
            <Row className="ThemeHead">
              <Col md={5} xs={12} className="p-5">
                <div className="pl-lg-5">
                  <p className="paletteTitle">Curated Palette</p>
                  <p className="paletteSubTitle">
                    Click the palette to apply colors on the wall
                  </p>

                  {themeView.colorPalette && (
                    <div className="pt-3 pb-3 ">
                      <input
                        type="radio"
                        name="image_palete"
                        id="r1"
                        value={1}
                        checked={
                          this.state.image_palete_value === 1 ? true : false
                        }
                        onChange={() =>
                          this.changePalete(
                            themeView.colorPalette.paletteDisplayImageUrl,
                            1
                          )
                        }
                      />
                      <img
                        className={
                          this.state.image_palete_value === 1
                            ? "active_palete_image img-fluid color_palate_image"
                            : "img-fluid color_palate_image"
                        }
                        alt="colorpalette"
                        src={
                          themeView.colorPalette
                            ? themeView.colorPalette.paletteImageUrl
                            : null
                        }
                        onClick={() =>
                          this.changePalete(
                            themeView.colorPalette.paletteDisplayImageUrl,
                            1
                          )
                        }
                      />
                    </div>
                  )}

                  {themeView.colorPalette1 && (
                    <div className="pt-3 pb-3 ">
                      <input
                        type="radio"
                        name="image_palete"
                        id="r2"
                        value={2}
                        checked={
                          this.state.image_palete_value === 2 ? true : false
                        }
                        onChange={() =>
                          this.changePalete(
                            themeView.colorPalette1.paletteDisplayImageUrl,
                            2
                          )
                        }
                      />
                      <img
                        className={
                          this.state.image_palete_value === 2
                            ? "active_palete_image img-fluid color_palate_image"
                            : "img-fluid color_palate_image"
                        }
                        alt="colorpalette1"
                        src={
                          themeView.colorPalette1
                            ? themeView.colorPalette1.paletteImageUrl
                            : null
                        }
                        onClick={() =>
                          this.changePalete(
                            themeView.colorPalette1.paletteDisplayImageUrl,
                            2
                          )
                        }
                      />
                    </div>
                  )}
                  {themeView.colorPalette2 && (
                    <div className="pt-3 pb-3 ">
                      <input
                        type="radio"
                        name="image_palete"
                        id="r3"
                        value={3}
                        checked={
                          this.state.image_palete_value === 3 ? true : false
                        }
                        onChange={() =>
                          this.changePalete(
                            themeView.colorPalette2.paletteDisplayImageUrl,
                            3
                          )
                        }
                      />
                      <img
                        className={
                          this.state.image_palete_value === 3
                            ? "active_palete_image img-fluid color_palate_image"
                            : "img-fluid color_palate_image"
                        }
                        alt="colorpalette2"
                        src={
                          themeView.colorPalette2
                            ? themeView.colorPalette2.paletteImageUrl
                            : null
                        }
                        onClick={() =>
                          this.changePalete(
                            themeView.colorPalette2.paletteDisplayImageUrl,
                            3
                          )
                        }
                      />
                    </div>
                  )}
                  {themeView.colorPalette3 && (
                    <div className="pt-3 pb-3 ">
                      <input
                        type="radio"
                        name="image_palete"
                        id="r3"
                        value={4}
                        checked={
                          this.state.image_palete_value === 4 ? true : false
                        }
                        onChange={() =>
                          this.changePalete(
                            themeView.colorPalette3.paletteDisplayImageUrl,
                            4
                          )
                        }
                      />
                      <img
                        className={
                          this.state.image_palete_value === 4
                            ? "active_palete_image img-fluid color_palate_image"
                            : "img-fluid color_palate_image"
                        }
                        alt="colorpalette2"
                        src={
                          themeView.colorPalette3
                            ? themeView.colorPalette3.paletteImageUrl
                            : null
                        }
                        onClick={() =>
                          this.changePalete(
                            themeView.colorPalette3.paletteDisplayImageUrl,
                            4
                          )
                        }
                      />
                    </div>
                  )}
                  {themeView.colorPalette4 && (
                    <div className="pt-3 pb-3 ">
                      <input
                        type="radio"
                        name="image_palete"
                        id="r3"
                        value={5}
                        checked={
                          this.state.image_palete_value === 5 ? true : false
                        }
                        onChange={() =>
                          this.changePalete(
                            themeView.colorPalette4.paletteDisplayImageUrl,
                            5
                          )
                        }
                      />
                      <img
                        className={
                          this.state.image_palete_value === 5
                            ? "active_palete_image img-fluid color_palate_image"
                            : "img-fluid color_palate_image"
                        }
                        alt="colorpalette2"
                        src={
                          themeView.colorPalette4
                            ? themeView.colorPalette4.paletteImageUrl
                            : null
                        }
                        onClick={() =>
                          this.changePalete(
                            themeView.colorPalette4.paletteDisplayImageUrl,
                            5
                          )
                        }
                      />
                    </div>
                  )}

                  {/*<div className="pt-3 pb-3 ">*/}
                  {/*    */}
                  {/*</div>*/}
                  <div className="pt-3 pb-3 color_palate_image_end">
                    <hr
                      style={{
                        borderTop: "5px dotted rgba(0, 0, 0, 0.39)",
                        display: "inline-block",
                      }}
                    />
                    <img
                      className="img-fluid color_palate_image"
                      alt="colorpalette2"
                      src={
                        themeView.eightColorPalette
                          ? themeView.eightColorPalette
                          : null
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col
                md={7}
                xs={12}
                className="pb-5 pl-lg-0 pr-lg-0 m-lg-0 m-1 "
                style={{
                  backgroundImage: "url(" + paletteImage + ")",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  minHeight: "50vh",
                }}
              ></Col>
            </Row>

            <div className="dynamic_colour_vision_component align-items-center">
              {/*suggested by prakash */}
              <svg
                className="us user_view_svg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1288.8 859.2"
                style={{ enableBackground: "new 0 0 1288.8 859.2" }}
                xmlSpace="preserve"
                width="100%"
                height="100%"
              >
                <style>
                  {
                    "\
                                            .st0{\
                                              fill:#EFB4B4;\
                                          }\
                                          .st1{\
                                              fill-rule:evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#B8E2E1;\
                                          }\
                                          .st2{\
                                              fill:#FFF5C4;\
                                              filter: drop-shadow(0 2px 4px #0003);\
                                          }\
                                          .st2:hover{\
                                              filter: drop-shadow(0 5px 10px #0004);\
                                              transition: all .1s ease-in-out;\
                                          }\
                                          .st3{\
                                              fill:none;\
                                              stroke:#000000;\
                                              stroke-width:2;\
                                              stroke-linecap:round;\
                                              }\
                                          .left_wall_icon{\
                                            cursor:pointer;\
                                          }\
                                          .right_wall_icon{\
                                            cursor:pointer;\
                                          }\
                                                    "
                  }
                </style>
                <g id="Layer_2_1_">
                  <image
                    style={{ overflow: "visible", enableBackground: "new" }}
                    height={"859.2"}
                    width={"1288.8"}
                    xlinkHref={this.state.image_url}
                    preserveAspectRatio="xMinYMin slice"
                  />
                </g>
                <g id="Layer_1_1_">
                  <g className="right_wall">
                    <path
                      className="st0"
                      d="M1189.1,529.4l-3.8-4.4l-1-1.7l-56.4-6.8l0.7-19.6l4.1-176.3l-11.6-7.9l-14,4.8l14-7.5l4.4-1l-5.5-3.4l7.2,2
			l3.4-0.7l-4.8-3.1v-2.4l6.8,2.7l1.7-58.1c0,0-6.8-8.2-22.9-7.9l-8.5-2.4l6.5-1.4l-38.3-14c0,0-10.2-3.8-30.4-4.4l-12.3-3.8
			l7.5-0.3l-27-5.8c0,0,27-0.3,45.4,6.5l8.2-0.3l-21.2-6.8h7.9c0,0-40-16.1-42.4-23.9l0.7-2.4l35.5,12l-9.9-8.9l-21.2-10.9l3.8-0.3
			l1.7-1.7l20.8,9.6c0,0,14,5.1,25.3,12.3s39.3,23.2,39.3,23.2l-8.2-8.9c0,0-2-1.1-4.2-2.3c-3-1.6-6.4-3.5-6.4-3.5l8.5,2.4
			c0,0-1.8-1.6-8.5-4.4c-8.2-3.4-9.6-4.8-9.6-4.8l-5.8-1l9.6-1c0,0-10.6-10.6-32.4-18.4l-2.1-3.4c0,0,18.8,5.8,26.3,9.2l-11.1-14.3
			l-5.8-2.2l1.5-2.6l-4.7-8.6l-4.7-6.5c0,0,12.1,7.8,14.4,11c7.1,1.9,12.3,3,12.3,3s16.4,2.8,31.7,9.7c-1.1-0.6,1.3-1.9-7.5-7.8
			c-5.6-3.4-7.1-5.6-7.1-5.6s-1.7-5.4,6.2-1.5l-6.2-11.6l-17-15.7l-0.4-5l-1.3-4.1c0,0,54.7,39.4,67,55.2c0,0-20.7-28.7-25.9-32.3
			c-6-7.5-38.4-31.7-38.4-31.7l0.9-1.1c0,0,25.2,18.3,28.7,19.4c3.4,1.1,14.9,9.3,27.4,24.4c7.3,5.8-2.6-8.6-2.6-8.6
			s-22.6-35.6-27.4-47.6c-0.7-1.8-0.9-9.7,6.9-4.3c6.9,8.8,26.5,31.2,31.5,37.3v-21.3l-9.1-14.7c0,0,0.4-3.2,2.8-1.1
			c2.4,3.7,7.1,11.9,7.1,11.9l2.5-102L848.4-1.5l0.2,1.9l-0.9,269.1l182-18h3.8l-0.6,166.1l-2.1,13.5l2.4,19.1l-49.8-3.2l2.1,5.9
			l20,1.8l8,2.9l-1.8,2.4l55.4,2.9l-3.2,134.6l114,18.8l2.7-3.4l-1.8-16.7c0,0-5.1-2.8,18.5-6.2c0,0,1.4-3.8-1.4-6.1
			c-2.7-2.4-0.7-6.1,1.7-7.5c1-2,0.3-19.5,0.3-19.5s1-14.2,3.8-17.3C1201.8,539.6,1196.7,532.1,1189.1,529.4z"
                    />
                    <path
                      className="st0"
                      d="M868.1,560.9l31,5.4l0.4-6.9l2.1-0.8l-2.7-0.8c0,0-0.8-1,2.7-1.7c3.5-0.6,29.3,0.6,35.7-3.1
			c0,0,2.1,1.7,8.7,2.5c6.6,0.8,22.7,3.3,22.7,3.3l1.5-71.1l-101-9.4L868.1,560.9z"
                    />
                    <path
                      className="st0"
                      d="M1208.5,557.9c-0.8-1.5,0.7-2.2,0.8-2.9s-0.5-11.6-0.5-11.6c-1.5-0.6-2.5,10.9-2.7,12.3
			c-0.2,1.3,1.3,3.5,1.3,3.5S1209.1,558.9,1208.5,557.9z"
                    />
                    <path
                      className="st0"
                      d="M1210.5,584.6c0,0-0.2-0.9,0.2-1.4c0.4-9.8-4.6-23.7-4.6-23.7c0.5,1.7-1.8,18.4-1.8,18.4l1.9,1.2
			L1210.5,584.6z"
                    />
                    <path
                      className="st0"
                      d="M1203.8,579.5c0.1,0.4-0.6,15.4-0.6,15.4l8.2-0.4c0,0-0.1-0.2-0.9-5.9S1203.8,579.5,1203.8,579.5z"
                    />
                    <path
                      className="st0"
                      d="M1232.8,569.2l2-5.1c0,0,1.5,0.4,0.8,1.7s-1.7,3-1.7,3s5.1-0.4,5.9-0.4l-0.2-11.5c0.5-0.5-7.7-14.9-7.7-14.9
			s-0.3-1.6,0.6-1s7.7,14.1,7.7,14.1l-0.2-21.2l-5.2-0.3c0,0-3.9,3-3.9,4.2s0.7,5-0.4,6.8c-0.6,1.8-0.5,22.8-0.5,23.6
			C1230,569,1232.8,569.2,1232.8,569.2z"
                    />
                    <path
                      className="st0"
                      d="M1239.5,578.1c-0.2-0.5,0.1-8.5,0.1-8.5c-0.4-0.2-6.2,0.3-6.2,0.3l-2.4,6c0,0-0.2,6.4-0.3,8.2
			c-0.1,1.7,0,10.3,0.4,10.4h8.1c0,0-3.1-6.3-3.6-7.2s0.7-0.8,0.7-0.8s2.7,6.3,3.7,5.6s-0.2-6-0.6-8.9
			C1239,580.4,1239.7,578.6,1239.5,578.1z"
                    />
                    <path
                      className="st0"
                      d="M1267,544.7l-0.4-7.6c-1.2-0.8-6.1-0.6-6.1-0.6c-0.9,2.7-1.5,25.9-1.5,27.5s-1.7,6.2-2.1,7.9
			c-0.4,1.6,1,1.8,2.1,0.6s2.2,0.2,2.2,0.2c-1,0-2.2,2.4-2.9,3.2s0,1.3,0,1.3c-2.8,1.3-1.6,4.7-2.1,5.8s0.8,4.6-0.1,4.9
			c-0.9,0.3,0.1,3.2,0.1,3.2c-1.9,1.2-0.8,4.4-0.8,4.4c1.3-0.1,8.6,0.4,8.6,0.4c-0.9-2.2,0.5-9.8,0.5-9.8s2.6-28.7,2.8-33.2
			C1265.7,550.9,1267,544.7,1267,544.7z"
                    />
                    <path
                      className="st0"
                      d="M1278.4,539c0,0-2.1,11-2.8,18.8s-3.7,37.8-3.9,39c0,0,13,1.2,16.6,1.8l0.7-59.4
			C1289,539.3,1279.5,538.5,1278.4,539z"
                    />
                    <path
                      className="st0"
                      d="M1230,571.4c0,0.6-0.6,3,0,3.3s2.4-4.5,2.4-4.5C1231.1,569.7,1230,570.7,1230,571.4z"
                    />
                    <path
                      className="st0"
                      d="M711.4,477l-9.5-1.4l-0.1-15.8l1-1l0.7-309.8V45.1l-0.9-1.5V28.1l9.6-4.2l1.4,0.3l64.5-24L704-1.7L518.8,73.1
			l-3,1.2l0.5,97.6l0.1,2.4l-0.1,14.3l0.3,3l1.7,200.9l0.9,109.5l267.4,49.4l0.5-66.2l-74-8.2H711.4z M573.8,433.8l-25.7,0.6
			l-0.7-20.9V209.8l1-55.4v-23c0,0-2.6-3,6-3.4c8.6-0.3,19.9,0.7,19.9,0.7L573.6,392L573.8,433.8z"
                    />
                  </g>
                  <path
                    className="st1 left_wall"
                    d="M515.3,155l-0.7-42.2l1.3-39.4l-35.4-4.5l-80.9-11l-80.4-11.3l-86.9-12.2l-70.1-10.1L83,13.1L0,1.4v19.7v46.3
		v54.7v68.4v60v182.6l0.2,5.3c0,0,8.5-1.3,12.1-2.2c3-0.8,13.4-1.9,15.4-1.9c1.9,0,3.8,1,6.5,2.2c2.2,1.1,3.1,1.2,3.1,1.2
		s1.3,0.4,4.8,1.2c2.5,1,13,3.8,13,3.8c11.7,1.6,20.9,7.9,20.9,7.9c2.3,2.6,3.2,2.3,7.7,3.4c1.4,0,13,0.5,13,0.5
		c23.5,1.7,31.9,7.8,31.9,7.8s19.8,0,26.2-0.5c7.3-0.5,29.2-0.4,29.2-0.4s15,1.5,15,7.2c0,7.3,0,43.2,0,43.2l280.7-11.2l21.1-2.3
		l18.7-0.1L515.3,155z M344.8,356.4c-35,0-70.1,0-105.1,0c0-51.3,0-102.7,0-154c35,4.7,70.1,9.4,105.1,14.1
		C344.8,263.1,344.8,309.8,344.8,356.4z"
                  />
                </g>
                <g
                  transform="matrix(1, 0, 0, 1, -152, -151.5)"
                  className="left_wall_icon"
                >
                  <circle className="st2" cx="524.5" cy="327.9" r="19" />
                </g>
                <path className="st3" d="M372.7,169v15.1" />
                <path className="st3" d="M380.2,176.6h-15.1" />

                <g transform="translate(899 151.5)" className="right_wall_icon">
                  <g transform="matrix(1, 0, 0, 1, -899, -151.5)">
                    <circle className="st2" cx="917.5" cy="170.9" r="19" />
                  </g>
                  <g transform="translate(11.692 11.692)">
                    <path className="st3" d="M7.5,0v15.1" />
                    <path className="st3" d="M15.1,7.5H0" />
                  </g>
                </g>
              </svg>

              <svg
                className="us user_not_view_svg"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 1288.8 859.2"
                style={{
                  enableBackground: "new 0 0 1288.8 859.2",
                  display: "none",
                }}
                xmlSpace="preserve"
                width="100%"
                height="100%"
              >
                <style type="text/css">
                  {
                    "\
                                            .st0{\
                                              fill:#EFB4B4;\
                                          }\
                                          .st1{\
                                              fill - rule:\
                                              evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#B8E2E1;\
                                          }\
                                          .st2{\
                                              fill - rule:evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#FFFFFF;\
                                          }\
                                          .st3{\
                                              fill - rule:evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#1C458A;\
                                          }\
                                          .st4{\
                                              fill - rule:evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#EC1C24;\
                                          }\
                                          .st5{\
                                              fill - rule:evenodd;\
                                              clip-rule:evenodd;\
                                              fill:#03428D;\
                                          }\
                                          .st6{\
                                              fill:#FFFFFF;\
                                          }\
                                          .st7{\
                                              fill:#F7981D;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st8{\
                                              fill:#355BA8;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st9{\
                                              fill:#652C8F;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st10{\
                                              fill:#C4248E;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st11{\
                                              fill:#6EBD44;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st12{\
                                              fill:#F16622;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st13{\
                                              fill:#09B3C2;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st14{\
                                              fill:#F6EB0F;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st15{\
                                              fill:#FFCC05;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st16{\
                                              fill:#92278E;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                          .st17{\
                                              fill:#0D9948;\
                                              stroke:#FFFFFF;\
                                              stroke-width:0.5;\
                                              stroke-miterlimit:10;\
                                          }\
                                         .st18{\
                                             fill:#EC1E24;\
                                             stroke:#FFFFFF;\
                                             stroke-width:0.5;\
                                             stroke-miterlimit:10;\
                                         }\
                                        "
                  }
                </style>
                <g id="Layer_2_1_">
                  <image
                    style={{ overflow: "visible", enableBackground: "new" }}
                    height={"859.2"}
                    width={"1288.8"}
                    xlinkHref={this.state.image_url}
                    preserveAspectRatio="xMinYMin slice"
                  />

                  {/*<image*/}
                  {/*    style={{overflow: "visible", enableBackground: "new"}}*/}
                  {/*    // width="1920" height="1280"*/}
                  {/*    xlinkHref={this.state.image_url} transform="matrix(0.6713 0 0 0.6713 0 0)">*/}
                  {/*</image>*/}
                </g>
                <g id="Layer_1_1_">
                  <g className="right_wall">
                    <path
                      className="st0"
                      d="M1189.1,529.4l-3.8-4.4l-1-1.7l-56.4-6.8l0.7-19.6l4.1-176.3l-11.6-7.9l-14,4.8l14-7.5l4.4-1l-5.5-3.4l7.2,2
			l3.4-0.7l-4.8-3.1v-2.4l6.8,2.7l1.7-58.1c0,0-6.8-8.2-22.9-7.9l-8.5-2.4l6.5-1.4l-38.3-14c0,0-10.2-3.8-30.4-4.4l-12.3-3.8
			l7.5-0.3l-27-5.8c0,0,27-0.3,45.4,6.5l8.2-0.3l-21.2-6.8h7.9c0,0-40-16.1-42.4-23.9l0.7-2.4l35.5,12l-9.9-8.9l-21.2-10.9l3.8-0.3
			l1.7-1.7l20.8,9.6c0,0,14,5.1,25.3,12.3s39.3,23.2,39.3,23.2l-8.2-8.9c0,0-2-1.1-4.2-2.3c-3-1.6-6.4-3.5-6.4-3.5l8.5,2.4
			c0,0-1.8-1.6-8.5-4.4c-8.2-3.4-9.6-4.8-9.6-4.8l-5.8-1l9.6-1c0,0-10.6-10.6-32.4-18.4l-2.1-3.4c0,0,18.8,5.8,26.3,9.2l-11.1-14.3
			l-5.8-2.2l1.5-2.6l-4.7-8.6l-4.7-6.5c0,0,12.1,7.8,14.4,11c7.1,1.9,12.3,3,12.3,3s16.4,2.8,31.7,9.7c-1.1-0.6,1.3-1.9-7.5-7.8
			c-5.6-3.4-7.1-5.6-7.1-5.6s-1.7-5.4,6.2-1.5l-6.2-11.6l-17-15.7l-0.4-5l-1.3-4.1c0,0,54.7,39.4,67,55.2c0,0-20.7-28.7-25.9-32.3
			c-6-7.5-38.4-31.7-38.4-31.7l0.9-1.1c0,0,25.2,18.3,28.7,19.4c3.4,1.1,14.9,9.3,27.4,24.4c7.3,5.8-2.6-8.6-2.6-8.6
			s-22.6-35.6-27.4-47.6c-0.7-1.8-0.9-9.7,6.9-4.3c6.9,8.8,26.5,31.2,31.5,37.3v-21.3l-9.1-14.7c0,0,0.4-3.2,2.8-1.1
			c2.4,3.7,7.1,11.9,7.1,11.9l2.5-102L848.4-1.5l0.2,1.9l-0.9,269.1l182-18h3.8l-0.6,166.1l-2.1,13.5l2.4,19.1l-49.8-3.2l2.1,5.9
			l20,1.8l8,2.9l-1.8,2.4l55.4,2.9l-3.2,134.6l114,18.8l2.7-3.4l-1.8-16.7c0,0-5.1-2.8,18.5-6.2c0,0,1.4-3.8-1.4-6.1
			c-2.7-2.4-0.7-6.1,1.7-7.5c1-2,0.3-19.5,0.3-19.5s1-14.2,3.8-17.3C1201.8,539.6,1196.7,532.1,1189.1,529.4z"
                    />
                    <path
                      className="st0"
                      d="M868.1,560.9l31,5.4l0.4-6.9l2.1-0.8l-2.7-0.8c0,0-0.8-1,2.7-1.7c3.5-0.6,29.3,0.6,35.7-3.1
			c0,0,2.1,1.7,8.7,2.5c6.6,0.8,22.7,3.3,22.7,3.3l1.5-71.1l-101-9.4L868.1,560.9z"
                    />
                    <path
                      className="st0"
                      d="M1208.5,557.9c-0.8-1.5,0.7-2.2,0.8-2.9s-0.5-11.6-0.5-11.6c-1.5-0.6-2.5,10.9-2.7,12.3
			c-0.2,1.3,1.3,3.5,1.3,3.5S1209.1,558.9,1208.5,557.9z"
                    />
                    <path
                      className="st0"
                      d="M1210.5,584.6c0,0-0.2-0.9,0.2-1.4c0.4-9.8-4.6-23.7-4.6-23.7c0.5,1.7-1.8,18.4-1.8,18.4l1.9,1.2
			L1210.5,584.6z"
                    />
                    <path
                      className="st0"
                      d="M1203.8,579.5c0.1,0.4-0.6,15.4-0.6,15.4l8.2-0.4c0,0-0.1-0.2-0.9-5.9S1203.8,579.5,1203.8,579.5z"
                    />
                    <path
                      className="st0"
                      d="M1232.8,569.2l2-5.1c0,0,1.5,0.4,0.8,1.7s-1.7,3-1.7,3s5.1-0.4,5.9-0.4l-0.2-11.5c0.5-0.5-7.7-14.9-7.7-14.9
			s-0.3-1.6,0.6-1s7.7,14.1,7.7,14.1l-0.2-21.2l-5.2-0.3c0,0-3.9,3-3.9,4.2s0.7,5-0.4,6.8c-0.6,1.8-0.5,22.8-0.5,23.6
			C1230,569,1232.8,569.2,1232.8,569.2z"
                    />
                    <path
                      className="st0"
                      d="M1239.5,578.1c-0.2-0.5,0.1-8.5,0.1-8.5c-0.4-0.2-6.2,0.3-6.2,0.3l-2.4,6c0,0-0.2,6.4-0.3,8.2
			c-0.1,1.7,0,10.3,0.4,10.4h8.1c0,0-3.1-6.3-3.6-7.2s0.7-0.8,0.7-0.8s2.7,6.3,3.7,5.6s-0.2-6-0.6-8.9
			C1239,580.4,1239.7,578.6,1239.5,578.1z"
                    />
                    <path
                      className="st0"
                      d="M1267,544.7l-0.4-7.6c-1.2-0.8-6.1-0.6-6.1-0.6c-0.9,2.7-1.5,25.9-1.5,27.5s-1.7,6.2-2.1,7.9
			c-0.4,1.6,1,1.8,2.1,0.6s2.2,0.2,2.2,0.2c-1,0-2.2,2.4-2.9,3.2s0,1.3,0,1.3c-2.8,1.3-1.6,4.7-2.1,5.8s0.8,4.6-0.1,4.9
			c-0.9,0.3,0.1,3.2,0.1,3.2c-1.9,1.2-0.8,4.4-0.8,4.4c1.3-0.1,8.6,0.4,8.6,0.4c-0.9-2.2,0.5-9.8,0.5-9.8s2.6-28.7,2.8-33.2
			C1265.7,550.9,1267,544.7,1267,544.7z"
                    />
                    <path
                      className="st0"
                      d="M1278.4,539c0,0-2.1,11-2.8,18.8s-3.7,37.8-3.9,39c0,0,13,1.2,16.6,1.8l0.7-59.4
			C1289,539.3,1279.5,538.5,1278.4,539z"
                    />
                    <path
                      className="st0"
                      d="M1230,571.4c0,0.6-0.6,3,0,3.3s2.4-4.5,2.4-4.5C1231.1,569.7,1230,570.7,1230,571.4z"
                    />
                    <path
                      className="st0"
                      d="M711.4,477l-9.5-1.4l-0.1-15.8l1-1l0.7-309.8V45.1l-0.9-1.5V28.1l9.6-4.2l1.4,0.3l64.5-24L704-1.7L518.8,73.1
			l-3,1.2l0.5,97.6l0.1,2.4l-0.1,14.3l0.3,3l1.7,200.9l0.9,109.5l267.4,49.4l0.5-66.2l-74-8.2H711.4z M573.8,433.8l-25.7,0.6
			l-0.7-20.9V209.8l1-55.4v-23c0,0-2.6-3,6-3.4c8.6-0.3,19.9,0.7,19.9,0.7L573.6,392L573.8,433.8z"
                    />
                  </g>
                  <path
                    className="st1 left_wall"
                    d="M515.3,155l-0.7-42.2l1.3-39.4l-35.4-4.5l-80.9-11l-80.4-11.3l-86.9-12.2l-70.1-10.1L83,13.1L0,1.4v19.7v46.3
		v54.7v68.4v60v182.6l0.2,5.3c0,0,8.5-1.3,12.1-2.2c3-0.8,13.4-1.9,15.4-1.9c1.9,0,3.8,1,6.5,2.2c2.2,1.1,3.1,1.2,3.1,1.2
		s1.3,0.4,4.8,1.2c2.5,1,13,3.8,13,3.8c11.7,1.6,20.9,7.9,20.9,7.9c2.3,2.6,3.2,2.3,7.7,3.4c1.4,0,13,0.5,13,0.5
		c23.5,1.7,31.9,7.8,31.9,7.8s19.8,0,26.2-0.5c7.3-0.5,29.2-0.4,29.2-0.4s15,1.5,15,7.2c0,7.3,0,43.2,0,43.2l280.7-11.2l21.1-2.3
		l18.7-0.1L515.3,155z M344.8,356.4c-35,0-70.1,0-105.1,0c0-51.3,0-102.7,0-154c35,4.7,70.1,9.4,105.1,14.1
		C344.8,263.1,344.8,309.8,344.8,356.4z"
                  />
                </g>
                <g>
                  <g>
                    <path
                      className="st2"
                      d="M1189.7,774.8l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2h2c0.1,0,0.2,0,0.2,0.1l1.6,3.1c0-0.4,0.1-0.8,0.2-1.2l0.3-1.9
			c0-0.1,0.1-0.2,0.3-0.2h1.8c0.1,0,0.2,0.1,0.2,0.2l-1.1,5.9c0,0.1-0.2,0.2-0.3,0.2h-2c-0.1,0-0.2,0-0.2-0.1l-1.6-3.2
			c0,0.4-0.1,0.8-0.2,1.2l-0.3,1.9c0,0.1-0.2,0.2-0.3,0.2h-1.8C1189.8,775,1189.7,774.9,1189.7,774.8"
                    />
                    <path
                      className="st2"
                      d="M1224.4,774.8l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2h2c0.1,0,0.2,0,0.2,0.1l1.6,3.1c0-0.4,0.1-0.8,0.2-1.2l0.3-1.9
			c0-0.1,0.1-0.2,0.3-0.2h1.8c0.1,0,0.2,0.1,0.2,0.2l-1.1,5.9c0,0.1-0.2,0.2-0.3,0.2h-2c-0.1,0-0.2,0-0.2-0.1l-1.6-3.2
			c0,0.4-0.1,0.8-0.2,1.2l-0.3,1.9c0,0.1-0.2,0.2-0.3,0.2h-1.8C1224.5,775,1224.3,774.9,1224.4,774.8"
                    />
                    <path
                      className="st2"
                      d="M1198.3,774.8l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2h2.1c0.1,0,0.2,0.1,0.2,0.2l-1.1,5.9c0,0.1-0.2,0.2-0.3,0.2h-2.1
			C1198.4,775,1198.3,774.9,1198.3,774.8"
                    />
                    <path
                      className="st2"
                      d="M1202.5,774.8l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2c1,0,1.9,0,2.9,0c0.6,0,1.4,0.1,1.9,0.5c0.9,0.8,0.4,2.3-0.5,2.9
			c-0.6,0.4-1.3,0.5-2,0.5h-0.8l-0.4,2.2c0,0.1-0.2,0.2-0.3,0.2h-2C1202.6,775,1202.5,774.9,1202.5,774.8 M1205.6,771.4h0.3
			c0.2,0,0.5-0.1,0.7-0.2c0.3-0.3,0.5-0.9,0.2-1.2c-0.2-0.2-0.4-0.2-0.6-0.2h-0.3L1205.6,771.4z"
                    />
                    <path
                      className="st2"
                      d="M1209.5,774.8l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2c1,0,1.9,0,2.9,0c0.6,0,1.4,0.1,1.9,0.5c0.9,0.8,0.4,2.3-0.5,2.9
			c-0.6,0.4-1.3,0.5-2,0.5h-0.8l-0.4,2.2c0,0.1-0.2,0.2-0.3,0.2h-2C1209.6,775,1209.4,774.9,1209.5,774.8 M1212.5,771.4h0.3
			c0.2,0,0.5-0.1,0.7-0.2c0.3-0.3,0.5-0.9,0.2-1.2c-0.2-0.2-0.4-0.2-0.6-0.2l-0.3,0L1212.5,771.4z"
                    />
                    <path
                      className="st2"
                      d="M1218,769.4c1.4-1.1,4-1.3,5.2,0c1.3,1.4,0.5,3.8-0.9,4.8c-1.5,1.1-4.2,1.1-5.2,0
			C1216,772.8,1216.7,770.5,1218,769.4 M1219.9,773.6c0.3,0,0.6-0.2,0.8-0.5c0.4-0.5,0.8-1.8,0.5-2.7c-0.1-0.3-0.4-0.5-0.7-0.5
			c-0.2,0-0.6,0.1-0.8,0.5c-0.4,0.6-0.8,1.9-0.5,2.7C1219.3,773.5,1219.6,773.6,1219.9,773.6"
                    />
                    <path
                      className="st2"
                      d="M1212.5,782.7l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2h2c0.1,0,0.2,0,0.2,0.1l1.6,3.1c0-0.4,0.1-0.8,0.2-1.2l0.3-1.9
			c0-0.1,0.1-0.2,0.3-0.2h1.8c0.1,0,0.2,0.1,0.2,0.2l-1.1,5.9c0,0.1-0.2,0.2-0.3,0.2h-2c-0.1,0-0.2,0-0.2-0.1l-1.6-3.2
			c0,0.4-0.1,0.8-0.2,1.2l-0.3,1.9c0,0.1-0.2,0.2-0.3,0.2h-1.8C1212.6,782.9,1212.5,782.8,1212.5,782.7"
                    />
                    <path
                      className="st2"
                      d="M1208.3,782.7l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2h2.1c0.1,0,0.2,0.1,0.2,0.2l-1.1,5.9c0,0.1-0.2,0.2-0.3,0.2h-2.1
			C1208.4,782.9,1208.3,782.8,1208.3,782.7"
                    />
                    <path
                      className="st2"
                      d="M1194.4,782.7l1.1-5.9c0-0.1,0.2-0.2,0.3-0.2c1,0,1.9,0,2.9,0c0.6,0,1.4,0.1,1.9,0.5c0.9,0.8,0.4,2.3-0.5,2.9
			c-0.6,0.4-1.3,0.5-2,0.5h-0.8l-0.4,2.2c0,0.1-0.2,0.2-0.3,0.2h-2C1194.5,782.9,1194.4,782.8,1194.4,782.7 M1197.4,779.3h0.3
			c0.2,0,0.5,0,0.7-0.2c0.3-0.3,0.5-0.9,0.2-1.1c-0.2-0.2-0.4-0.2-0.6-0.2h-0.3L1197.4,779.3z"
                    />
                    <path
                      className="st2"
                      d="M1200.1,782.6l3.3-5.9c0-0.1,0.2-0.1,0.3-0.1h2.5c0.1,0,0.2,0,0.2,0.1l1,5.9c0,0.1-0.1,0.3-0.3,0.3h-2
			c-0.1,0-0.2-0.1-0.2-0.2l-0.1-0.8h-1.9l-0.4,0.8c0,0.1-0.2,0.2-0.3,0.2h-2C1200.1,782.9,1200,782.8,1200.1,782.6 M1203.6,780.5
			h1.1l-0.1-1.3c0-0.3,0-0.6,0-0.9c-0.1,0.3-0.2,0.6-0.4,1L1203.6,780.5z"
                    />
                    <path
                      className="st2"
                      d="M1223.1,778h-1.4c-0.1,0-0.2-0.1-0.2-0.2l0.2-1.1c0-0.1,0.2-0.2,0.3-0.2h5.4c0.1,0,0.2,0.1,0.2,0.2l-0.2,1.1
			c0,0.1-0.2,0.2-0.3,0.2h-1.4l-0.8,4.6c0,0.1-0.2,0.2-0.3,0.2h-2.1c-0.1,0-0.2-0.1-0.2-0.2L1223.1,778z"
                    />
                    <path
                      className="st2"
                      d="M1184.8,766.8h-15.8v15.4c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,1,0.7,1.6,0.7h15.8l0-15.4c0-0.6-0.3-1.2-0.7-1.7
			C1185.9,767.1,1185.4,766.8,1184.8,766.8"
                    />
                    <path
                      className="st3"
                      d="M1169.8,767.7h14.9c0.8,0,1.5,0.7,1.5,1.5v14.5h-15c-0.8,0-1.5-0.7-1.5-1.5V767.7z"
                    />
                    <path
                      className="st2"
                      d="M1177,780.8v-4.9c0-1.5,1.8-1.8,2-0.2c0.2,1.3-1,3.6-1.9,5.1C1177,780.9,1177,780.9,1177,780.8 M1177,770.3
			v-0.6v-0.3c0-0.2-0.1-0.3-0.3-0.3h-4.6c-0.2,0-0.3,0.2-0.3,0.3v11.6c0,0.2,0.2,0.3,0.3,0.3h11.2c0.2,0,0.2-0.1,0.2-0.3l-0.1-0.5
			c-0.2-1.3,1.2-3.7,1.1-6.2c-0.1-1.9-1.2-3.5-2.9-4.3C1180.2,769.5,1178.4,769.5,1177,770.3"
                    />
                    <path
                      className="st4"
                      d="M1172.4,770.4h4v1.2c0.8-0.8,1.9-1.2,3-1.2c1.9,0,3.8,1.3,4.3,3.2c0.8,2.6-1.2,5.6-0.9,7.3h-5
			c1.4-2.3,1.9-4.1,1.8-5.1c-0.2-1.6-1.7-2-2.7-1.2c-0.5,0.5-0.6,1-0.6,1.7v4.7h-4V770.4z"
                    />
                    <path
                      className="st5"
                      d="M1168.8,766.8h15.9c0.6,0,1.3,0.3,1.7,0.7c0.4,0.4,0.7,1.1,0.7,1.7v15.4h-15.9c-0.6,0-1.2-0.3-1.7-0.7
			c-0.4-0.4-0.7-1.1-0.7-1.7V766.8z M1184.8,766.8h-15.8v15.4c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,1,0.7,1.6,0.7h15.8l0-15.4
			c0-0.6-0.3-1.2-0.7-1.7C1185.9,767.1,1185.4,766.8,1184.8,766.8"
                    />
                  </g>
                  <g>
                    <path
                      className="st6"
                      d="M1163,803.5L1163,803.5c0-5.5,4.1-10,10-10c2.9,0,4.8,0.8,6.4,1.9c0.4,0.3,0.8,0.9,0.8,1.7
			c0,1.2-0.9,2.1-2.1,2.1c-0.6,0-1-0.2-1.3-0.4c-1.2-0.9-2.4-1.4-3.9-1.4c-3.2,0-5.6,2.7-5.6,6v0.1c0,3.3,2.3,6,5.6,6
			c1.8,0,3-0.5,4.2-1.5c0.3-0.3,0.8-0.5,1.3-0.5c1.1,0,2,0.9,2,1.9c0,0.7-0.3,1.2-0.7,1.5c-1.7,1.5-3.8,2.4-6.8,2.4
			C1167.2,813.4,1163,809,1163,803.5z"
                    />
                    <path
                      className="st6"
                      d="M1217.9,795.8c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1v13.4h7.8c1.1,0,1.9,0.9,1.9,1.9
			c0,1.1-0.9,1.9-1.9,1.9h-9.9c-1.2,0-2.1-0.9-2.1-2.1V795.8z"
                    />
                    <path
                      className="st6"
                      d="M1233,803.5L1233,803.5c0-5.5,4.3-10,10.2-10c5.9,0,10.2,4.4,10.2,9.9v0.1c0,5.5-4.3,9.9-10.2,9.9
			C1237.2,813.4,1233,809,1233,803.5z M1249,803.5L1249,803.5c0-3.3-2.4-6.1-5.8-6.1s-5.8,2.7-5.8,6v0.1c0,3.3,2.4,6,5.8,6
			S1249,806.8,1249,803.5z"
                    />
                    <path
                      className="st6"
                      d="M1257.2,796c0-1.2,0.9-2.1,2.1-2.1h6.7c2.4,0,4.3,0.7,5.6,1.9c1.1,1.1,1.6,2.6,1.6,4.4v0.1
			c0,3-1.5,4.9-3.8,5.8l2.9,3.6c0.4,0.5,0.7,1,0.7,1.6c0,1.2-1,1.9-2,1.9c-1,0-1.6-0.5-2.1-1.1l-4.1-5.2h-3.4v4.2
			c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1V796z M1265.7,803.2c2.1,0,3.2-1.1,3.2-2.7v-0.1c0-1.8-1.3-2.7-3.3-2.7h-4.2v5.5
			H1265.7z"
                    />
                    <g>
                      <path
                        className="st6"
                        d="M1173.1,838.9l-6.2-14.9c-0.1-0.3-0.2-0.7-0.2-1c0-1.2,0.9-2.1,2.1-2.1c1.1,0,1.8,0.6,2.1,1.5l4.8,12.4
				l4.9-12.6c0.3-0.7,1-1.3,2-1.3c1.2,0,2.1,0.9,2.1,2c0,0.3-0.1,0.7-0.2,0.9l-6.3,15c-0.4,1-1.2,1.7-2.4,1.7h-0.3
				C1174.3,840.5,1173.5,839.9,1173.1,838.9z"
                      />
                      <path
                        className="st6"
                        d="M1189.7,823c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1v15.3c0,1.2-0.9,2.1-2.1,2.1
				c-1.2,0-2.1-0.9-2.1-2.1V823z"
                      />
                      <path
                        className="st6"
                        d="M1200,838.2c-0.5-0.3-0.8-0.9-0.8-1.6c0-1.1,0.9-1.9,2-1.9c0.5,0,0.9,0.2,1.2,0.4c1.5,1.1,3,1.7,4.9,1.7
				c1.7,0,2.8-0.7,2.8-1.8v-0.1c0-1.1-0.7-1.6-3.9-2.4c-3.9-1-6.4-2.1-6.4-5.9v-0.1c0-3.5,2.8-5.8,6.7-5.8c2.3,0,4.4,0.6,6.1,1.7
				c0.5,0.3,0.9,0.9,0.9,1.7c0,1.1-0.9,1.9-2,1.9c-0.4,0-0.7-0.1-1.1-0.3c-1.4-0.8-2.7-1.3-4-1.3c-1.6,0-2.5,0.7-2.5,1.7v0.1
				c0,1.3,0.8,1.7,4.1,2.5c3.9,1,6.1,2.4,6.1,5.8v0.1c0,3.8-2.9,6-7.1,6C1204.7,840.5,1202.1,839.7,1200,838.2z"
                      />
                      <path
                        className="st6"
                        d="M1219.8,823c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1v15.3c0,1.2-0.9,2.1-2.1,2.1
				c-1.2,0-2.1-0.9-2.1-2.1V823z"
                      />
                      <path
                        className="st6"
                        d="M1229.6,830.7L1229.6,830.7c0-5.5,4.3-10,10.2-10c5.9,0,10.2,4.4,10.2,9.9v0.1c0,5.5-4.3,9.9-10.2,9.9
				C1233.9,840.6,1229.6,836.1,1229.6,830.7z M1245.6,830.7L1245.6,830.7c0-3.3-2.4-6.1-5.8-6.1c-3.4,0-5.8,2.7-5.8,6v0.1
				c0,3.3,2.4,6,5.8,6C1243.2,836.7,1245.6,834,1245.6,830.7z"
                      />
                      <path
                        className="st6"
                        d="M1255.4,823c0-1.2,0.9-2.1,2.1-2.1h0.4c1,0,1.6,0.5,2.2,1.2l8.2,10.7v-9.9c0-1.2,0.9-2.1,2.1-2.1
				c1.2,0,2.1,0.9,2.1,2.1v15.3c0,1.2-0.9,2.1-2.1,2.1h-0.1c-1,0-1.6-0.5-2.2-1.2l-8.5-11.1v10.3c0,1.2-0.9,2.1-2.1,2.1
				c-1.2,0-2.1-0.9-2.1-2.1V823z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      className="st7"
                      d="M1191.2,790.1c0,0.2,0,0.4,0,0.5c-0.1,2.2,0.4,4.4,1.4,6.4c0.8,1.7,1.9,3.2,3.2,4.5c0.3,0.3,0.7,0.7,1,1
			c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.1,0.4-0.4,0.4c-0.6-0.1-1.2-0.2-1.8-0.4c-2.7-0.8-5.1-2.3-7-4.4c-0.8-0.9-1.5-1.9-2-2.9
			c-0.1-0.2-0.1-0.3,0-0.5c1.4-1.8,3-3.4,5-4.5C1190.8,790.3,1191,790.3,1191.2,790.1z"
                    />
                    <path
                      className="st8"
                      d="M1205.3,817c0-0.2,0-0.3,0-0.4c0.1-3-0.8-5.8-2.5-8.3c-0.9-1.3-1.9-2.5-3.1-3.5c-0.2-0.2-0.3-0.5,0-0.7
			c0.1,0,0.2,0,0.3,0c2.6,0.4,4.9,1.5,7,3.1c1.6,1.3,2.9,2.8,3.9,4.7c0.1,0.2,0.1,0.3,0,0.4c-1.4,1.9-3.1,3.4-5.1,4.6
			C1205.6,816.8,1205.5,816.9,1205.3,817z"
                    />
                    <path
                      className="st9"
                      d="M1211,811.7c-0.1-0.1-0.1-0.2-0.2-0.3c-1.7-3.2-4.4-5.4-7.8-6.7c-1-0.4-2-0.6-3-0.9c-0.2,0-0.3-0.1-0.3-0.3
			c0-0.2,0-0.4,0.3-0.4c1.4-0.5,2.9-0.8,4.4-0.9c3.2-0.2,6.1,0.6,8.8,2.2c0.2,0.1,0.3,0.2,0.3,0.5c-0.3,2.3-1,4.5-2.1,6.5
			C1211.2,811.5,1211.1,811.6,1211,811.7z"
                    />
                    <path
                      className="st10"
                      d="M1211.8,796.7c-0.2,0-0.3,0-0.4,0c-3.8-0.1-7.2,1.2-10,3.6c-0.7,0.6-1.3,1.2-1.9,1.8
			c-0.1,0.1-0.2,0.3-0.4,0.1c-0.2-0.1-0.3-0.2-0.3-0.5c0.2-1.3,0.6-2.5,1.2-3.6c0.8-1.7,1.9-3.2,3.2-4.5c1-1,2.2-1.8,3.5-2.5
			c0.2-0.1,0.4-0.1,0.6,0.1c1.8,1.4,3.3,3.1,4.5,5.2C1211.7,796.5,1211.7,796.6,1211.8,796.7z"
                    />
                    <path
                      className="st11"
                      d="M1184.9,810.6c0.3,0,0.5,0,0.8,0c2.7,0,5.2-0.8,7.4-2.2c1.5-0.9,2.8-2.1,3.9-3.4c0.1-0.2,0.2-0.2,0.4-0.1
			c0.4,0.1,0.4,0.3,0.3,0.7c-0.5,2.8-1.7,5.2-3.6,7.4c-1.1,1.2-2.4,2.2-3.8,3.1c-0.3,0.2-0.4,0.1-0.7-0.1c-1.8-1.4-3.3-3-4.5-4.9
			C1185.1,811,1185,810.8,1184.9,810.6z"
                    />
                    <path
                      className="st12"
                      d="M1198.9,788.5c-0.2,0.3-0.4,0.6-0.5,0.9c-0.9,1.6-1.5,3.4-1.7,5.2c-0.3,2.5,0,5,0.8,7.3
			c0.1,0.2,0.1,0.3-0.1,0.4c-0.2,0.1-0.3,0.2-0.5,0c-1.1-0.9-2.1-2-2.9-3.2c-1.3-1.9-2.1-4-2.4-6.2c-0.1-0.9-0.1-1.9-0.1-2.8
			c0-0.1,0.1-0.3,0.2-0.3c1.4-0.6,2.8-1,4.3-1.2c0.9-0.1,1.7-0.1,2.6-0.2C1198.7,788.4,1198.8,788.5,1198.9,788.5z"
                    />
                    <path
                      className="st13"
                      d="M1197.7,818.6c0.2-0.3,0.3-0.5,0.4-0.8c0.9-1.6,1.5-3.3,1.7-5.1c0.3-2.5,0-5-0.8-7.4c-0.1-0.4,0-0.5,0.3-0.7
			c0.1,0,0.2,0,0.3,0.1c0.7,0.6,1.4,1.3,1.9,2c1.7,2,2.8,4.3,3.3,6.9c0.2,1,0.3,2.1,0.3,3.2c0,0.2-0.1,0.3-0.3,0.4
			c-1.6,0.7-3.2,1.2-5,1.3c-0.6,0.1-1.2,0.1-1.8,0.1C1198,818.7,1197.9,818.7,1197.7,818.6z"
                    />
                    <path
                      className="st14"
                      d="M1183.1,803.2c0.6,0.3,1.1,0.6,1.6,0.8c1.4,0.7,2.9,1.1,4.5,1.2c2.5,0.2,4.8-0.1,7.2-0.9
			c0.3-0.1,0.6,0.1,0.7,0.4c0,0.1-0.1,0.2-0.1,0.2c-0.7,0.9-1.5,1.7-2.4,2.4c-2.1,1.6-4.5,2.7-7.1,3.1c-0.8,0.1-1.6,0.1-2.4,0.2
			c-0.2,0-0.3-0.1-0.4-0.2c-0.7-1.6-1.2-3.2-1.4-4.9c-0.1-0.6-0.1-1.2-0.2-1.9C1183.1,803.4,1183.1,803.3,1183.1,803.2z"
                    />
                    <path
                      className="st15"
                      d="M1185.5,795.6c0.2,0.3,0.4,0.6,0.5,0.9c1.5,2.3,3.4,4.1,5.9,5.3c1.4,0.7,2.9,1.2,4.5,1.5
			c0.1,0,0.3,0,0.3,0.2c0,0.2,0.2,0.5-0.2,0.6c-1.6,0.5-3.2,0.9-4.9,0.9c-2.9,0.1-5.6-0.7-8.2-2.1c-0.2-0.1-0.3-0.2-0.2-0.4
			c0.3-2.4,0.9-4.6,2.1-6.7C1185.3,795.7,1185.4,795.7,1185.5,795.6z"
                    />
                    <path
                      className="st16"
                      d="M1213.4,804.3c-0.3-0.2-0.6-0.4-0.9-0.5c-1.6-0.9-3.3-1.5-5.1-1.7c-2.5-0.3-5,0-7.4,0.8
			c-0.2,0.1-0.3,0.1-0.5-0.1c-0.2-0.2-0.1-0.4,0.1-0.6c2.3-2.7,5.2-4.4,8.7-5.1c1.1-0.2,2.2-0.3,3.3-0.3c0.3,0,0.5,0.1,0.6,0.4
			c0.6,1.6,1.1,3.3,1.2,5c0.1,0.6,0.1,1.1,0.1,1.7C1213.4,804.1,1213.4,804.2,1213.4,804.3z"
                    />
                    <path
                      className="st17"
                      d="M1190.3,816.4c0.6-0.4,1.2-0.7,1.7-1.1c2.4-1.8,4.1-4.1,5.1-6.9c0.4-1,0.6-2,0.8-3c0-0.2,0.1-0.2,0.3-0.2
			c0.2,0,0.4-0.2,0.5,0.2c0.4,1.1,0.7,2.1,0.8,3.3c0.1,0.9,0.1,1.9,0.1,2.8c-0.1,2.4-0.7,4.6-1.9,6.7c-0.3,0.6-0.4,0.6-1.1,0.5
			c-2.1-0.3-4.2-0.9-6.1-2C1190.6,816.6,1190.5,816.5,1190.3,816.4z"
                    />
                    <path
                      className="st18"
                      d="M1206.5,790.9c-0.3,0.1-0.5,0.3-0.7,0.4c-2.3,1.3-4,3.1-5.3,5.4c-0.9,1.6-1.5,3.3-1.9,5.1
			c0,0.2,0,0.4-0.3,0.4c-0.3,0-0.4-0.1-0.5-0.3c-0.7-1.9-0.9-3.8-0.8-5.8c0.1-2.5,0.9-4.9,2.2-7.1c0.1-0.2,0.3-0.4,0.6-0.3
			c2.3,0.3,4.5,1,6.5,2.2C1206.3,790.8,1206.4,790.8,1206.5,790.9z"
                    />
                  </g>

                  <ellipse
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -217.2289 1082.6638)"
                    className="st6"
                    cx="1198.3"
                    cy="803.6"
                    rx="4.6"
                    ry="4.6"
                  />
                </g>
              </svg>

              <canvas
                id="canvas"
                width="1288.8"
                height="859.2"
                style={{ display: "none" }}
              />

              <Container className="color_content">
                <div className="content_section d-flex justify-content-between align-items-center">
                  <div className="description_part">
                    <h3 className="content_section_title">
                      Your Colour Vision
                    </h3>
                    <p className="content_section_para">
                      Click on the plus icon and choose the color to apply on
                      the wall
                    </p>
                  </div>
                  {this.state.hide_button === false && (
                    <span>
                      <button
                        onClick={this.convertSvgToPng}
                        className="btn desktop_download_btn"
                      >
                        DOWNLOAD
                      </button>
                    </span>
                  )}
                </div>
                <div className="dynamic_colour_vision_color_palate row flex-nowrap">
                  {this.state.sortedColorTypes !== undefined ? (
                    this.state.sortedColorTypes.map(
                      function (item, i) {
                        return (
                          <label className="col p-0" key={i}>
                            <input
                              type="radio"
                              name="radio"
                              checked={
                                this.state.selected_color === item.node.rgb
                              }
                              value={this.state.selected_color}
                              onChange={() => this.fillColor(item.node.rgb)}
                            />
                            <div
                              className={
                                this.state.selected_color === item.node.rgb
                                  ? "front-end box d-flex flex-column align-items-center justify-content-center active_color"
                                  : "front-end box d-flex flex-column align-items-center justify-content-center"
                              }
                              style={{
                                backgroundColor: this.getFormattedColor(
                                  item.node.rgb
                                ),
                              }}
                            >
                              <span className="color_title">
                                {item.node.colorName}
                              </span>
                              <span className="color_code">
                                {item.node.description}
                              </span>
                            </div>
                          </label>
                        )
                      }.bind(this)
                    )
                  ) : (
                    <div className="empty_msg" style={{ color: "#000" }}>
                      {this.state.loadMsg}
                    </div>
                  )}
                </div>

                <div
                  id="demo"
                  className="carousel slide mobile_carousel"
                  data-ride="carousel"
                >
                  <ul className="carousel-indicators">
                    <li
                      data-target="#demo"
                      data-slide-to="0"
                      className="active"
                    />
                    <li data-target="#demo" data-slide-to="1" />
                    <li data-target="#demo" data-slide-to="2" />
                    <li data-target="#demo" data-slide-to="3" />
                  </ul>

                  <div className="carousel-inner">
                    {ThemeColorsArray.map((colorItem, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index === 0
                              ? "carousel-item active"
                              : "carousel-item"
                          }
                        >
                          <div className="row">
                            {colorItem.map((item, i) => {
                              return (
                                <div className="col-6 p-0" key={i}>
                                  <label className="item w-100">
                                    <input
                                      type="radio"
                                      name="radio"
                                      checked={
                                        this.state.selected_color ===
                                        item.node.rgb
                                      }
                                      value={this.state.selected_color}
                                      onChange={() =>
                                        this.fillColor(item.node.rgb)
                                      }
                                    />
                                    <div
                                      className={
                                        this.state.selected_color ===
                                        item.node.rgb
                                          ? "front-end box d-flex flex-column align-items-center justify-content-center active_color"
                                          : "front-end box d-flex flex-column align-items-center justify-content-center"
                                      }
                                      style={{
                                        backgroundColor: this.getFormattedColor(
                                          item.node.rgb
                                        ),
                                      }}
                                    >
                                      <span className="color_title">
                                        {item.node.colorName}
                                      </span>
                                      <span className="color_code">
                                        {item.node.description}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                    {/*<div className="carousel-item active">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#F9F7E8'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#F9F7E8')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#F9F7E8' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#F9F7E8'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#E7ECE5'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#E7ECE5')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#E7ECE5' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end  box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#E7ECE5'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="carousel-item">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#E0E4E3'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#E0E4E3')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#E0E4E3' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#E0E4E3'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#DFE7EA'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#DFE7EA')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#DFE7EA' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#DFE7EA'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="carousel-item">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#D9D7DC'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#D9D7DC')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#D9D7DC' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#D9D7DC'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#C7F9E6'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#C7F9E6')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#C7F9E6' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#C7F9E6'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="carousel-item">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#D5E000'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#D5E000')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#D5E000' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#D5E000'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6 p-0">*/}
                    {/*            <label className="item w-100">*/}
                    {/*                <input type="radio" name="radio"*/}
                    {/*                       checked={this.state.selected_color === '#F9F7E8'}*/}
                    {/*                       value={this.state.selected_color}*/}
                    {/*                       onChange={() => this.fillColor('#F9F7E8')}*/}
                    {/*                />*/}
                    {/*                <div*/}
                    {/*                    className={this.state.selected_color === '#F9F7E8' ? 'front-end box d-flex flex-column align-items-center justify-content-center active_color' : "front-end box d-flex flex-column align-items-center justify-content-center"}*/}
                    {/*                    style={{backgroundColor: '#F9F7E8'}}>*/}
                    {/*                    <span className="color_title">Whisper White</span>*/}
                    {/*                    <span className="color_code">NP OW 1001 P</span>*/}
                    {/*                </div>*/}
                    {/*            </label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
                {this.state.hide_button === false && (
                  <button
                    onClick={this.convertSvgToPng}
                    className="mobile_download_btn"
                  >
                    DOWNLOAD
                  </button>
                )}
              </Container>
            </div>
            <p
              className="ThemeExploreTitle pt-5 pb-3 m-0"
              style={{ color: "#000" }}
            >
              Explore the Color Forecasts for India
            </p>
            <Row>
              <Col md={1} />
              <Col
                md={3}
                xs={12}
                className=" ThemeExploreBg1 text-center m-lg-4 mt-4 "
                onClick={(event) => this.goToTheme(event, 1, "youth")}
              >
                {/*<img className="img-fluid" src={theme1} style={{opacity: '0.9'}}/>*/}
                <div className="carousel-caption" style={{ top: "50%" }}>
                  <p className="indiaBloomSubTitle m-0">Colors of</p>
                  <p className="list-region_title">Youth </p>
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className="ThemeExploreBg2 text-center m-lg-4 mt-4 "
                onClick={(event) => this.goToTheme(event, 2, "north")}
              >
                {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                <div className="carousel-caption" style={{ top: "50%" }}>
                  <p className="indiaBloomSubTitle m-0">Colors of</p>
                  <p className="list-region_title">North </p>
                </div>
              </Col>
              <Col
                md={3}
                xs={12}
                className="ThemeExploreBg3 text-center m-lg-4 mt-4"
                onClick={(event) => this.goToTheme(event, 3, "south")}
              >
                {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                <div className="carousel-caption" style={{ top: "50%" }}>
                  <p className="indiaBloomSubTitle m-0">Colors of</p>
                  <p className="list-region_title">South </p>
                </div>
              </Col>
              <Col md={1} />
            </Row>

            <Footer />
          </Container>
        </div>
      </MetaWrapper>
    )
  }
}

export default withRouter(ColorVisionThemePage)
