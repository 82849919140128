import React, {useEffect} from "react";
import {withRouter} from "react-router";


const ScrollToTop = ({ children, location: { pathname } }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default withRouter(ScrollToTop);


