import React from "react"
import "./IndiaBloomPage.css"
import Container from "react-bootstrap/Container"
import $ from "jquery"
import { Col } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Modal from "react-bootstrap/Modal"
import { withRouter } from "react-router"
import ScrollToTop from "../ScrollToTop"
import cogoToast from "cogo-toast"
import MetaWrapper from "../../MetaWrapper"
import client from "../../Apollo/apolloClient"
import { REGIONS_LIST_QUERY } from "../../Apollo/queries/RegionListQuery"
import color_solor  from "./../../Assets/Images/color_solor.png"
import color_box from "./../../Assets/Images/color-box.png"

class IndiaBloomPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlayed: false,
      IndiaBloomModalShow: true,
    }
  }

  componentDidMount() {
    $("#section1").on("click", function (e) {
      e.preventDefault()
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        500
      )
      return false
    })
    let self = this

    $("#exploreIndia").click(function () {
      $(this).toggleClass("is-active")

      self.setState({
        IndiaBloomModalShow: !self.state.IndiaBloomModalShow,
      })
      if (self.state.IndiaBloomModalShow) {
        document.getElementById("HeaderNav").style.zIndex = "10"
      } else {
        document.getElementById("HeaderNav").style.zIndex = "1200"
      }
    })

    // Fetch regions list using ApolloClient
    client
      .query({
        query: REGIONS_LIST_QUERY,
      })
      .then((response) => {
        if (response.data && response.data.regionsList) {
          this.setState({ regionList: response.data.regionsList })
        } else {
          cogoToast.error("There is no data", { position: "top-center" })
        }
      })
      .catch((error) => {
        console.error("Error fetching regions list:", error)
        cogoToast.error("Failed to fetch data", { position: "top-center" })
      })
  }

  closeModal() {
    this.setState({
      IndiaBloomModalShow: false,
    })
    this.props.history.push("/")
  }
  goThemeListPage(eve, regionid, region) {
    this.props.history.push("/forecasts/" + region + "/" + regionid)

    this.setState({
      IndiaBloomModalShow: false,
    })
  }

  render() {
    return (
      <MetaWrapper
        meta={{
          description: "India Bloom",
          title: "India Bloom",
        }}
      >
        <ScrollToTop />
        <Container fluid={true} className="p-0">
          <Modal
            size="lg"
            show={this.state.IndiaBloomModalShow}
            onHide={() =>
              this.setState({
                IndiaBloomModalShow: false,
              })
            }
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="indiaBloomModal pl-0"
            backdrop="static"
          >
            <div className="CloseBtn" onClick={() => this.closeModal()}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <Modal.Body className="p-0">
              <div className="indiaBloomBg" />
              <div className="text-center">

                <div className="indiaBloomModalArticle text-justify pl-lg-5 pr-lg-5 pt-4 pl-3 pr-3">
                  <p className="pl-lg-5 pr-lg-5">
                    Colors are omnipresent in our lives and are part of
                    everything that comes with living. In a culturally
                    diversified and plural country like India, colors have
                    always been a crucial thread binding our emotional, social
                    and cultural fabric. As with the Domino Effect, this could
                    consequently affect our hormonal surge which would then
                    impact our bio-behaviour.
                  </p>
                  <p className="pl-lg-5 pr-lg-5">
                    The Hypothalamus, a structure located deep within the brain,
                    experiences the impact of colors via signals from the
                    retinal ganglion cells. Each of these hues and shades
                    carries meta-tags with memories in the context of cultural,
                    social, emotional, and bio-behavioral reactions. A key point
                    to note here is that individual colors don’t express
                    significant meaning, only a group of colors do. This is why,
                    at Nippon Paint, we chose to emphasize color archetypes as
                    stories and themes. In this second edition of the Nippon
                    Paint India ColorVision 2024-2025, the forecast includes
                    color archetypes that were projected as key themes of 2024
                    and 2025.
                  </p>
                  <p className="pl-lg-5 pr-lg-5">
                    While the theme HEAL reflects the need for comfort and the
                    dreamlike surrealism of existing in a peaceful state, the
                    archetype BLOOMERS puts the focus on the steadily growing
                    popularity of ‘handmade and craft’ culture, where
                    individuals yearn to learn new skills that could bring
                    creative ideas to life. The mood of HIVE emphasizes on the
                    need for cohabitation and bonding, while the theme of
                    SOULCORE persuades one to seek the answer to a question that
                    has puzzled mankind, ‘Who am I?’ - an eternal enquiry to
                    find the ‘self’. The colors of these 4 key archetypes are
                    shown in Figure. 1.
                  </p>
                  <div style={{textAlign:"center"}}>
                    <img src={color_solor} alt={""} style={{ width: "100%", height: "auto",maxWidth:"600px" }} />
                  </div>
                  <p className="pl-lg-5 pr-lg-5">
                    Nippon Paint India ColorVision is the first ever zonal and
                    youth color forecast for India that recognizes and respects
                    the fact that color meaning and impact is geo-specific and
                    culture centric. The emotional facets and bio-behaviour of
                    the various color expressions vary according to the vast
                    geography of India. Therefore, in this edition of forecast
                    the archetypes are segmented according to their zonal
                    relevance. Additionally, the ‘Youth’ section highlights 12
                    distinct color archetypes, embodying the expressions and
                    moods of the Indian youth. These are thoughtfully
                    categorized under north, south and youth segments in this
                    edition. The unique ColorVision forecasting process spans
                    over 16 months and involves approximately 30,000 meta-tags —
                    color clues processed through multiple focus groups,
                    including invited influencers, color catalysts, domain
                    experts, and color practitioners. This year, more than 55
                    participants from Mumbai, Delhi, Chennai, Hyderabad, Pune,
                    Kolkata, Pondicherry, Bangalore, Ahmedabad, etc., were
                    engaged in various research progress levels, and some are
                    acknowledged at the end of this book as a token of gratitude
                    for their contributions. Nippon Paint India ColourVision's
                    color foresight system is based on the copyrighted method of
                    Dr. Kaustav SenGupta, the Lead Forecaster for Nippon Paint
                    ColorVision. The method incorporates a unique 'Color
                    Foresight Cube' (SenGupta, 2018), mapping color signifiers
                    through signals and drivers to indicate shifts and
                    understand plausible color directions based on emotional,
                    bio-behavioral, cultural, and social meta-tags. The 3Px3C
                    framework (SenGupta, 2018) forms the macro base for this
                    color mapping system. Dr. SenGupta's color foresight cube is
                    displayed in Figure. 2 for reference. 
                    </p>
                  <div style={{textAlign:"center"}}>
                    <img src={color_box} alt={""} style={{ width: "100%", height: "auto",maxWidth:"600px" }} />
                  </div>
                  <p className="pl-lg-5 pr-lg-5"> 
                    Although this forecast
                    primarily targets home color (interior and exterior home
                    walls, furnishings, décor, accessories, tableware, lighting,
                    etc.), it can be applied to other product segments like
                    personal goods, digital electronics, FMCG, and automobiles,
                    as these color archetypes can impact various domains.
                  </p>
                  {/* <p className="pl-lg-5 pr-lg-5">
                    {" "}
                    Let’s celebrate the plurality and enjoy the BLOOM.
                  </p> */}
                  <p
                    className="ThemeExploreTitle p-3 m-0"
                    style={{
                      color: "#000",
                      fontFamily: '"Neo-Sans", sans-serif',
                    }}
                  >
                    Explore the Color Forecasts for India
                  </p>
                  <Row>
                    <Col md={1} />
                    <Col
                      md={3}
                      xs={12}
                      className=" ThemeExploreBg1 text-center m-lg-4 mt-4 "
                      onClick={(event) =>
                        this.goThemeListPage(event, 1, "youth")
                      }
                    >
                      {/*<img className="img-fluid" src={theme1} style={{opacity: '0.9'}}/>*/}
                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">Youth Theme</p>
                      </div>
                    </Col>
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg2 text-center m-lg-4 mt-4 "
                      onClick={(event) =>
                        this.goThemeListPage(event, 2, "north")
                      }
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">North Theme</p>
                      </div>
                    </Col>
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg3 text-center m-lg-4 mt-4"
                      onClick={(event) =>
                        this.goThemeListPage(event, 3, "south")
                      }
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">South Theme</p>
                      </div>
                    </Col>
                    <Col md={1} />
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </MetaWrapper>
    )
  }
}

export default withRouter(IndiaBloomPage)
