import React from "react"
import "./App.css"
import HomePage from "./Components/HomePage/HomePage"
import ScrollToTop from "./Components/ScrollToTop"
import MetaWrapper from "./MetaWrapper"
import HomePageModal from "./Components/HomePageModal/HomePageModal"

function App() {
  return (
    <div>
      <MetaWrapper
        meta={{
          description: "Nippon Paint Color Vision",
          title: "Nippon Paint Color Vision",
        }}
      >
        <ScrollToTop />
        <HomePage />
        <HomePageModal/>
      </MetaWrapper>
    </div>
  )
}

export default App
