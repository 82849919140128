import { gql } from '@apollo/client';

export const REGIONS_LIST_QUERY = gql`
  query HomePageQuery {
    regionsList {
      id
      regionName
      regionId
      themeFeaturedImage
    }
  }
`;


