import React from "react"
import Container from "react-bootstrap/Container"
import "./Themelistingpage.css"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import ZoneImg from "./../../Assets/Images/South_theme_bg.png"
import { withRouter } from "react-router"
import ScrollToTop from "./../ScrollToTop"
import MetaWrapper from "../../MetaWrapper"
import ForecastFormPage from "../ForecastFormPage/ForecastFormPage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import client from "../../Apollo/apolloClient"
import { REGIONS_LIST_QUERY } from "../../Apollo/queries/RegionListQuery"
import { GET_THEME_LIST_QUERY } from "../../Apollo/queries/GetThemeList"

class ThemelistingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      themeList: [],
      loadingMsg: "",
      currentRegion: "",
      regionId: "",
      zoneImage: "",
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.GetRegionList()
  }

  componentDidMount() {
    // let Region = this.props.history.location.state.region.regionName;
    // let regionId = this.props.history.location.state.region.regionId
    // this.setState({
    //     currentRegion: Region
    // })

    // let scope = this;
    // this.setState({
    //     regionId :parseInt(regionId)
    // },()=>{

    this.GetRegionList()
    // })
  }
  goToTheme = (eve, regionid, region) => {
    this.props.history.push("/forecasts/" + region + "/" + regionid)

    this.GetRegionList()

    //
    // if(regionId === 1){
    //     this.setState({
    //         currentRegion : "Youth"
    //     })
    // }
    // else if(regionId === 2){
    //     this.setState({
    //         currentRegion : "North"
    //     })
    // }
    // else if(regionId === 3){
    //     this.setState({
    //         currentRegion : "South"
    //     })
    // }
    // this.setState({
    //     regionId :parseInt(regionId)
    // },()=>{
    //     this.GetThemeList()
    // })

    // let self = this;
    // fetchQuery(environment, RegionListQuery, variables)
    //     .then(data => {
    //
    //         if (data.regionsList !== null) {
    //             this.setState({
    //                 regionId :parseInt(data.regionsList.regionId)
    //             })
    //
    //             self.GetThemeList()
    //
    //         } else {
    //             cogoToast.error("There is no data", {position: 'top-center'});
    //         }
    //
    //     });
  }
  goSingleThemePage = (event, theme) => {
    debugger
    this.props.history.push("/theme/" + theme.themeName + "/" + theme.themeId, {
      theme: theme,
    })
  }

  GetRegionList() {
    const variables = {}

    client
      .query({
        query: REGIONS_LIST_QUERY,
        variables: variables,
      })
      .then((response) => {
        const data = response.data

        if (data.regionsList.length !== 0) {
          let selected_region = data.regionsList.filter((user) => {
            return user.regionId === parseInt(this.props.match.params.regionid)
          })

          this.setState(
            {
              zoneImage: selected_region[0].themeFeaturedImage,
              currentRegion: selected_region[0].regionName,
              regionId: selected_region[0].regionId,
            },
            () => {
              this.GetThemeList()
            }
          )
        } else {
          this.setState({
            zoneImage: ZoneImg,
          })
        }
      })
      .catch((error) => {
        console.error("Error fetching region list:", error)
        // Handle error here
      })
  }

  GetThemeList() {
    const variables = {
      regionId: parseInt(this.state.regionId),
    }

    client
      .query({
        query: GET_THEME_LIST_QUERY,
        variables: variables,
      })
      .then((response) => {
        const data = response.data

        if (data.themesBasedOnRegion.length !== 0) {
          this.setState({
            themeList: data.themesBasedOnRegion,
          })
        } else {
          this.setState({
            themeList: data.themesBasedOnRegion,
            loadingMsg: "There are no themes",
          })
        }
      })
      .catch((error) => {
        console.error("Error fetching theme list:", error)
      })
  }

  getFormattedColor = (color_code) => {
    var array = color_code.split(",").map(Number)
    let rgb = "rgb(" + array[0] + "," + array[1] + "," + array[2] + ")"

    return rgb
  }
  goToForcast = (e) => {
    this.props.history.push("/forecast-form")
  }
  render() {
    let zoneImage

    zoneImage = this.state.zoneImage

    return (
      <MetaWrapper
        meta={{
          description: "Forecast " + this.props.match.params.region + " themes",
          title: "Forecast " + this.props.match.params.region + " themes",
        }}
      >
        <ScrollToTop />

        <div className="ThemeListPage">
          <Container
            fluid={true}
            className=" theme_bg p-0"
            style={{
              background: "url(" + this.state.zoneImage + ") no-repeat top",
              backgroundSize: "100% 50vh",
            }}
          >
            <div>
              <Header
                regionId={this.state.regionId}
                gotoThemeListPage={this.goToTheme}
              />
              {/*<ForecastFormPage />*/}
              <div className="buttonDiv">
                <Button
                  className="forecastformBtn"
                  onClick={() => this.goToForcast()}
                >
                  <FontAwesomeIcon
                    icon={faCloudDownloadAlt}
                    style={{ marginRight: "10px" }}
                  />
                  DOWNLOAD FORECAST 24-25
                </Button>
              </div>
              <div className=" pt-4  text-center ">
                <p className="ThemeListTitle p-5">{this.state.currentRegion}</p>

                <Row className="m-0 d-flex text-center align-items-center justify-content-center">
                  {this.state.themeList.length > 0 &&
                    this.state.themeList.map((item, index) => {
                      debugger
                      console.log("item", item)
                      return (
                        <div key={index}>
                          {item !== null && (
                            <Card className="m-3" key={index}>
                              <Card.Img variant="top" src={item.url} />
                              <Card.Body
                                style={{
                                  backgroundColor: item.rgb
                                    ? this.getFormattedColor(item.rgb)
                                    : "#D68B5B",
                                }}
                                className="text-left theme_list_card_body"
                              >
                                <Card.Img
                                  src={item.url2}
                                  className="p-2 custom-card-img"
                                  style={{
                                    height: "75px",
                                    maxWidth: "100%",
                                    width: "auto",
                                  }}
                                />

                                <Button
                                  className="CardBtn p-0 m-2"
                                  onClick={(event) =>
                                    this.goSingleThemePage(event, item)
                                  }
                                >
                                  EXPLORE
                                </Button>
                              </Card.Body>
                            </Card>
                          )}
                        </div>
                      )
                    })}
                  {this.state.themeList.length === 0 && (
                    <div className="empty_msg">{this.state.loadingMsg}</div>
                  )}

                  {/*<Card className="m-3"   >*/}
                  {/*    <Card.Img variant="top"  src={ThemeImg1} />*/}
                  {/*    <Card.Body style={{ backgroundColor: '#D68B5B'}} className="text-left" >*/}
                  {/*        <Card.Img src={ThemeLogo1} className="p-2" style={{height:'75px', maxWidth:"100%",width:"auto"}}/>*/}

                  {/*        <Button className="CardBtn p-0 m-2" onClick={() => this.goSingleThemePage()}>EXPLORE THEME</Button>*/}
                  {/*    </Card.Body>*/}
                  {/*</Card>*/}

                  {/*<Card className="m-3"  >*/}
                  {/*    <Card.Img variant="top"  src={ThemeImg2}  />*/}
                  {/*    <Card.Body style={{ backgroundColor: '#41554C'}}  className="text-left">*/}
                  {/*        <Card.Img src={ThemeLogo2} className="p-2" style={{height:'75px', maxWidth:"100%",width:"auto"}}/>*/}
                  {/*        <Button className="CardBtn p-0 m-2" onClick={() => this.goSingleThemePage()}>EXPLORE THEME</Button>*/}
                  {/*    </Card.Body>*/}
                  {/*</Card>*/}

                  {/*<Card className="m-3" >*/}
                  {/*    <Card.Img variant="top"  src={ThemeImg3}  />*/}
                  {/*    <Card.Body style={{ backgroundColor: '#7B7A7B'}}  className="text-left" >*/}
                  {/*        <Card.Img src={ThemeLogo3}  className="p-2 " style={{height:'75px', maxWidth:"100%",width:"auto"}}/>*/}
                  {/*        <Button className="CardBtn p-0 m-2" onClick={() => this.goSingleThemePage()}>EXPLORE THEME</Button>*/}
                  {/*    </Card.Body>*/}
                  {/*</Card>*/}
                  {/*<Card className="m-3" >*/}
                  {/*    <Card.Img variant="top" src={ThemeImg4} />*/}
                  {/*    <Card.Body style={{ backgroundColor: '#AA4717'}}  className="text-left">*/}
                  {/*        <Card.Img src={ThemeLogo4} className="p-2 " style={{height:'75px', maxWidth:"100%",width:"auto"}}/>*/}
                  {/*        <Button className="CardBtn p-0 m-2" onClick={() => this.goSingleThemePage()}>EXPLORE THEME</Button>*/}
                  {/*    </Card.Body>*/}
                  {/*</Card>*/}
                </Row>
                <p
                  className="ThemeExploreTitle pt-5 pb-3 m-0"
                  style={{ color: "#000" }}
                >
                  Explore Other Color Forecasts for India
                </p>
                <Row className="justify-content-center">
                  {this.state.regionId !== 1 && (
                    <Col
                      md={3}
                      xs={12}
                      className=" ThemeExploreBg1 text-center m-lg-4 mt-4 "
                      onClick={(event) => this.goToTheme(event, 1, "youth")}
                    >
                      {/*<img className="img-fluid" src={theme1} style={{opacity: '0.9'}}/>*/}
                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">Youth</p>
                      </div>
                    </Col>
                  )}
                  {this.state.regionId !== 2 && (
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg2 text-center m-lg-4 mt-4 "
                      onClick={(event) => this.goToTheme(event, 2, "north")}
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">North</p>
                      </div>
                    </Col>
                  )}
                  {this.state.regionId !== 3 && (
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg3 text-center m-lg-4 mt-4"
                      onClick={(event) => this.goToTheme(event, 3, "south")}
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div className="carousel-caption" style={{ top: "50%" }}>
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">South</p>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              <Footer />
            </div>
          </Container>
        </div>
      </MetaWrapper>
    )
  }
}

export default withRouter(ThemelistingPage)
