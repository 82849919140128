import { gql } from '@apollo/client';

export const GET_SINGLE_THEME_QUERY = gql`
  query ColorVisionThemePageQuery($themeId: Int!) {
    singleThemeView(themeId: $themeId) {
      id
      description
      headerImageLeft
      headerImageRight
      colorPalette {
        id
        paletteImageUrl
        paletteDisplayImageUrl
      }
      colorPalette1 {
        paletteImageUrl
        paletteDisplayImageUrl
      }
      colorPalette2 {
        paletteImageUrl
        paletteDisplayImageUrl
      }
      colorPalette3 {
        paletteImageUrl
        paletteDisplayImageUrl
      }
      colorPalette4 {
        paletteImageUrl
        paletteDisplayImageUrl
      }
      colorPalette5 {
        paletteImageUrl
        paletteDisplayImageUrl
      }
      eightColorPalette
      colorsSet(first: 100) {
        edges {
          node {
            colorName
            description
            rgb
            displayOrder
          }
        }
      }
      url
    }
  }
`;