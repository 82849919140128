import React from "react"
import { withRouter } from "react-router"
import "./HomePageModal.css"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import cogoToast from "cogo-toast"
import FileSaver from "file-saver"
import client from "../../Apollo/apolloClient"
import { CREATE_FORECAST_FORM_MUTATION } from "../../Apollo/mutations/CreateForecastFormMutation"
import Footer from "../Footer/Footer"
import { Modal } from "react-bootstrap"

class HomePageModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Name: "",
      Email: "",
      PhoneNumber: "",
      Profession: "",
      Company: "",
      City: "",
      EmailFieldError: "",
      NumberFieldError: "",
      emptyNameField: "",
      emptyField: "",
      emptyPhoneNumField: "",
      emptyEmailField: "",
      emptyProfessionField: "",
      emptyCompanyField: "",
      emptyCityField: "",
      isActive: true,
    }
  }
  componentDidMount() {
    // Automatically open the modal when the component mounts
    this.openModal()
  }
  handleChangeInput = (event) => {
    if (event.target.name === "Name") {
      this.setState({
        Name: event.target.value,
        emptyNameField: "",
      })
    } else if (event.target.name === "phoneNumber") {
      const reg = /^[1-9]{1}[0-9]{9}$/
      let NumlValid = reg.test(event.target.value)

      let fieldValidationErrors = NumlValid ? "" : "PhoneNumber is invalid"

      if (event.target.value.length < 10 || event.target.value.length > 10) {
        this.setState({
          NumberFieldError: "PhoneNumber is invalid",
          PhoneNumber: event.target.value,
          emptyPhoneNumField: "",
        })
      } else {
        this.setState({
          NumberFieldError: fieldValidationErrors,
          PhoneNumber: event.target.value,
          emptyPhoneNumField: "",
        })
      }
    } else if (event.target.name === "Email") {
      let emailValid = event.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      )
      let fieldValidationErrors = emailValid ? "" : "Email is invalid"
      this.setState({
        EmailFieldError: fieldValidationErrors,
        Email: event.target.value,
        emptyEmailField: "",
      })
    } else if (event.target.name === "Profession") {
      this.setState({
        Profession: event.target.value,
        emptyProfessionField: "",
      })
    } else if (event.target.name === "City") {
      this.setState({
        City: event.target.value,
        emptyCityField: "",
      })
    } else if (event.target.name === "Company") {
      this.setState({
        Company: event.target.value,
        emptyCompanyField: "",
      })
    }
  }

  DownloadPdf = () => {
    // window.scrollTo({
    //     top: document.documentElement.scrollHeight,
    //     behavior: 'smooth'
    // });

    let Name = this.state.Name
    let mobileNumber = this.state.PhoneNumber
    let Email = this.state.Email
    let Profession = this.state.Profession
    let City = this.state.City
    let Company = this.state.Company

    if (Name === "") {
      this.setState({
        emptyNameField: "Enter Your Name",
      })
    } else if (Email === "") {
      this.setState({
        emptyEmailField: "Enter Your Email",
      })
    } else if (mobileNumber === "") {
      this.setState({
        emptyPhoneNumField: "Enter Your PhoneNumber",
      })
    } else if (Profession === "") {
      this.setState({
        emptyProfessionField: "Enter Your Profession",
      })
    } else if (City === "") {
      this.setState({
        emptyCityField: "Enter Your City",
      })
    } else {
      this.setState({
        emptyField: "",
        emptyNameField: "",
        emptyPhoneNumField: "",
        emptyEmailField: "",
        emptyProfessionField: "",
        emptyCityField: "",
        isActive: false,
      })

      const UserFormData = {
        email: Email,
        mobileNumber: mobileNumber,
        name: Name,
        profession: Profession,
        city: City,
        companyName: Company,
      }
      debugger
      client
        .mutate({
          mutation: CREATE_FORECAST_FORM_MUTATION,
          variables: UserFormData,
        })
        .then((response) => {
          if (response.data && response.data.downloadPdf !== null) {
            cogoToast.success("Downloading...", { position: "top-center" })
            this.setState({
              Name: "",
              Email: "",
              PhoneNumber: "",
              Profession: "",
              Company: "",
              City: "",
            })
            FileSaver.saveAs(
              "https://www.nipponpaintcolorvision.com/asset/Nippon_WebBook.pdf",
              "Nippon_WebBook.pdf"
            )
            this.closeModal()
          } else {
            cogoToast.error("Please try again", { position: "top-center" })
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          cogoToast.error("Failed to fetch data", { position: "top-center" })
        })
    }
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  goToHome() {
    this.props.history.push("/")
  }

  render() {
    if (this.state.isActive === false) {
      document.getElementById("ContactFormBtn").disabled = true
      document.getElementById("ContactFormBtn").style.cursor = "not-allowed"
      document.getElementById("ContactFormBtn").style.backgroundColor =
        "#b1adad"
      document.getElementById("ContactFormBtn").style.border =
        "2px solid #b1adad"
    }

    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          centered
          className="custom-backdrop"
        >
          <Modal.Body>
            <div className="ForecastformMbl">
              <div className="form-Column-modal">
                <p className="Forecastformtitle">
                  Get Access to India's First Zone Specific Color Forecast
                </p>
                <Form className="mt-4 text-left">
                  <Form.Group controlId="formBasicName" className="mb-4">
                    <Form.Label className="label-name">Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="Name"
                      placeholder="John Doe"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Name}
                    />
                    {this.state.emptyNameField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyNameField}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-4">
                    <Form.Label className="label-name">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="john@example.com"
                      onChange={(event) => this.handleChangeInput(event)}
                      name="Email"
                      value={this.state.Email}
                    />
                    {this.state.EmailFieldError !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.EmailFieldError}
                      </p>
                    ) : null}
                    {this.state.emptyEmailField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyEmailField}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicNumber" className="mb-4">
                    <Form.Label className="label-name">
                      Mobile Number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="9876543210"
                      name="phoneNumber"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.PhoneNumber}
                    />
                    {this.state.NumberFieldError !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.NumberFieldError}
                      </p>
                    ) : null}
                    {this.state.emptyPhoneNumField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyPhoneNumField}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicName" className="mb-4">
                    <Form.Label className="label-name">Profession</Form.Label>
                    <Form.Control
                      type="text"
                      name="Profession"
                      placeholder=""
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Profession}
                    />
                    {this.state.emptyProfessionField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyProfessionField}
                      </p>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicName" className="mb-4">
                    <Form.Label className="label-name">Company</Form.Label>
                    <Form.Control
                      type="text"
                      name="Company"
                      placeholder=""
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Company}
                    />
                    {this.state.emptyCompanyField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyCompanyField}
                      </p>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicName" className="mb-4">
                    <Form.Label className="label-name">City</Form.Label>
                    <Form.Control
                      type="text"
                      name="City"
                      placeholder=""
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.City}
                    />
                    {this.state.emptyCityField !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.emptyCityField}
                      </p>
                    ) : null}
                  </Form.Group>

                  <Button
                    type="button"
                    id="ContactFormBtn"
                    className="w-100 rounded-0  forecastContactformSubmitBtnn  mt-3 mb-3"
                    onClick={(event) => this.DownloadPdf(event)}
                  >
                    Download Color Vision 2024-2025 E-book
                  </Button>
                  <Button
                    variant="danger"
                    className="w-100 rounded-0"
                    onClick={this.closeModal}
                  >
                    Close
                  </Button>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default withRouter(HomePageModal)
