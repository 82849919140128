import { gql } from '@apollo/client';

export const CREATE_FORECAST_FORM_MUTATION = gql`
  mutation CreateForecastFormMutation(
    $email: String!
    $mobileNumber: String!
    $name: String!
    $profession: String!
    $city:String!
    $companyName:String
  ) {
    downloadPdf(
      email: $email
      mobileNumber: $mobileNumber
      name: $name
      profession: $profession
      city: $city
      companyName: $companyName
    ) {
      status
      message
    }
  }
`

