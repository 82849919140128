import React from "react";
import Container from "react-bootstrap/Container";
import './Footer.css';
import NipponLogo from './../../Assets/Images/new-Nippon-logo.png'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pathname: '',
        }
    }
    componentDidMount() {


    }

    render() {
        const { pathname } = window.location;
        return (
            <div>
                <Container fluid={true} className="p-0">
                    <footer className="colorvision-footer" style={pathname === "/forecast-form" ?
                        { bottom: "95px" }
                        :
                        pathname === "/team-color-vision"?{bottom: "0"}:{ bottom: "47px" }
                    }>
                        <div className="mini-footer">
                            <Container>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <div className="copyright-text">
                                            <p>An initiative by
                                                &nbsp;&nbsp;
                                                <img src={NipponLogo} className="img-fluid nipponLogo" style={{ width: "10%" }} />
                                                {/*<a href="#">DigiPro</a>. All rights reserved. Created by*/}
                                                {/*<a href="#">AazzTech</a>*/}
                                            </p>
                                        </div>

                                        {/*<div className="go_top">*/}
                                        {/*    <span className="icon-arrow-up"/>*/}
                                        {/*</div>*/}
                                    </Col>
                                </Row>
                            </Container>
                            <div style={{ color: "white", padding: "24px" }}>
                                <div style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>For inquiries regarding the purchase of the hardbound edition of our Color Vision 2024-25 forecast, kindly reach out to us at</div>
                                <div style={{ color: "white", fontSize: "15px" }}>Email: tu@nipponpaint.co.in</div>
                                <div style={{ color: "white", fontSize: "15px" }}>Phone: 8749087490</div>
                                <div style={{ color: "white", fontSize: "15px" }}>Phone: 7305079642</div>
                            </div>
                            {/* <div style={{color:"white",display:"flex",justifyContent: "space-around"}}>
                                <div></div>
                                <div></div>
                            </div> */}

                        </div>
                    </footer>
                </Container>
            </div>
        );
    }
}

export default Footer
