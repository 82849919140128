import React from "react"
import { withRouter } from "react-router"
import ScrollToTop from "../ScrollToTop"
import Header from "../Header/Header"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import "./ColorVisionTeam.css"
import Container from "react-bootstrap/Container"
import MetaWrapper from "../../MetaWrapper"
import client from "../../Apollo/apolloClient"
import { TEAM_LIST_QUERY } from "../../Apollo/queries/TeamListQuery"

class ColorVisionTeamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamList: [],
    }
  }
  componentDidMount() {
    this.getTeamList()
  }
  getTeamList = () => {
    let variables = {}

    client
      .query({
        query: TEAM_LIST_QUERY,
        variables: variables,
      })
      .then((response) => {
        const { data } = response
        if (data && data.teamList && data.teamList.length > 0) {
          this.setState({
            teamList: data.teamList,
          })
        } else {
          this.setState({
            teamList: [],
          })
        }
      })
      .catch((error) => {
        console.error("Error fetching team list:", error)
      })
  }

  render() {
    return (
      <MetaWrapper
        meta={{
          description: "Key Catalysts",
          title: "Key Catalysts",
        }}
      >
        <ScrollToTop />
        <div className="TeamPage">
          <Header />
          <>
            <Container fluid={true}>
              <p className="TeamTitle pb-5 text-uppercase">Key Catalysts</p>
              <Row>
                {this.state.teamList.map((team, index) => {
                  return (
                    <Col
                      className="text-center mb-3"
                      md={4}
                      lg={3}
                      sm={12}
                      key={index}
                    >
                      <img
                        className="img-fluid"
                        src={team.url}
                        alt="team_image"
                        width="100%"
                      />
                      <p className="TeamName pt-3">{team.name}</p>
                      <p className="TeamDesc m-0">{team.role}</p>
                      <p className="TeamDesc">{team.place}</p>
                      <p className="TeamDesc m-0">{team.description}</p>
                    </Col>
                  )
                })}
              </Row>

              <Row>
                <Col className="mb-xs-3 mb-md-5  text-center">
                  <h3 className="teams"> Millennial Catalysts</h3>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="pt-sm-2">
                  <p>Akshaya- psychologist </p>

                  <p>Abinaya- Youtuber</p>

                  <p> Arun Raj- Calligraphy Artist</p>

                  <p> Bala Murli- Chief Officer(Merchant Navy)</p>
                  <p> Bianca Joseph- Artist</p>
                </Col>
                <Col md={3}>
                  <p> Neena Kumar- Fashion Influencer</p>

                  <p> Nidhi- Mixed Media Artist </p>

                  <p> Pakhi Singh- Peer Leader </p>

                  <p> Nair- Peer Leader</p>

                  <p> Poornima S- Peer Leader</p>
                </Col>

                <Col md={3}>
                  <p> Neena Kumar- Fashion Influencer</p>

                  <p> Nidhi- Mixed Media Artist </p>

                  <p> Pakhi Singh- Peer Leader </p>

                  <p> Nair- Peer Leader</p>

                  <p> Poornima S- Peer Leader</p>

                  <p> Prachi Pearl Baptist- Peer Leader</p>
                </Col>
                <Col>
                  <p>Shishir Ramnath- Photographer </p>

                  <p> Srivatsan Sankaran- Photographer</p>

                  <p>Swetha S- Peer Leader</p>
                </Col>
              </Row>
            </Container>
          </>
        </div>
      </MetaWrapper>
    )
  }
}

export default withRouter(ColorVisionTeamPage)
