import React from "react";
import './TeamColorVision.css';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MetaWrapper from "../../MetaWrapper";
class TeamColorVision extends React.Component {
    render() {
        return (
            <MetaWrapper
                meta={{
                    description: "Team Color Vision",
                    title: "Team Color Vision",
                }}
            >
                <div className="team_color_vision_component">
                    <Header />
                    <div className="container-fluid pt-5 pb-5">
                        <div className="row ">
                            <div className="col text-center">
                                <h1 className="text-uppercase team_color_vision_title">Team Color Vision</h1>
                            </div>

                        </div>
                        <div className="row mt-5">
                            <div className="col text-center">
                                <h3 className="teams">NIFT Core Team: Research and Insights</h3>


                                <p>Dr. Kaustav SenGupta Head Futurist, </p>
                                <p>Lead Color Forecaster - Nippon Paint ColorVision </p>
                                <p>Associate Professor - NIFT, Chennai</p>
                                <br />

                                <h3 className="teams">Research Intern</h3>
                                <p>Ms. Poornima Santhanam (2021) </p>
                                {/* <p>Ms. Bhavitraa Thilagar </p>
                            <p>Ms. Suneyna Tandon </p>
                            <p>Ms. Lipika Aggarwal </p> */}
                                <br />
                                <h3 className="teams">NIFT Core Team: Project Management</h3>
                                <p>Dr. Kaustav SenGupta - Associate Professor NIFT & Director Insights - VisioNxt</p>
                                <p>Dr. R. Nandhakumar, RA</p>
                                <p>Mr. Arun Pillai, MTS</p>

                                {/* <p>Dr. Kaustav SenGupta - Associate Professor and Project Head</p>
                            <p>Dr. R Nandhakumar, RA </p>
                            <p>Mr. Arun Pillai, MTS </p>
                            <p>Mrs. Shaswati Sengupta - Guest Faculty </p> */}
                                <br />
                                <h3 className="teams">Nippon Paint Core Team: Management and Support</h3>
                                <p>Mr. Mahesh Anand, President - Decorative Division</p>
                                <p>Mr. Mark Titus, Assistant Vice President</p>
                                <p>Mr. Hari Hara Sudhan, AGM - Marketing Communication</p>
                                <p>Mr. Sathish Kumar, Senior Manager - Trade Marketing</p>
                                <p>Ms. Nithi Shanmugam, Manager - Specifiers Segment & Speciality Coating</p>
                                <p>Mr. Arulprakash, Associate Manager - Color Marketing & Retail Merchandise</p>
                                <p>Ms. Shreya Anand, Assistant Manager - Content Marketing</p>
                                {/* <p>Mr. Martin Thomas - Shoots and boards </p>
                            <p>Ms. Sulakshana- Shoots, boards and illustrations </p>
                            <p>Mr. Sayed Ayan Sulaiman - Shoots and boards </p>
                            <p>Ms. Keerthana Chandrasekhar - Shoots and boards </p>
                            <p>Ms. Kaninica SenGupta – Boards & Illustrations </p> */}

                                {/* <h3 className="teams">Nippon Paint Core Team: Management and support </h3>
                            <p>Mr. Mahesh Anand, President - Decoratives</p>
                            <p>Mr. Mark Titus - Director, Marketing</p>
                            <p>Mr. Sathish Kumar - Area Marketing Manager - South & Special Products</p>
                            <p>Ms. Shveta Vishwanath - Divisional Manager - Specifers Segment</p>
                            <p>Mr. Arulprakash - Senior Executive - Colour Marketing & Retail Merchandise</p>
                            <p>Mr. Aswin Kumar - Assistant Manager - Digital Marketing</p>
                            <p>Ms. Sanjana Murali - Management Trainee </p> */}
                                <br />
                                <h3 className="teams">Book Design Team</h3>
                                <p>Rubecon Communications</p>
<br/>
                                <h3 className="teams">Website Design and Development</h3>
                                <p>Deepsense Digital </p>
<br/>
                                <h3 className="teams">Printed By</h3>
                                <p>Pragati Offset Pvt. Ltd., Hyderabad</p>
                                <br />                         
                                 <h3 className="teams">Disclaimer</h3>
                                <p>

                                    All rights reserved. All content in this color forecast book is
                                    owned/copyrighted/trademarked by Nippon Paint. No part of this book may be reproduced,
                                    modified, transmitted, reprinted or published in any form or by any means without prior
                                    permission in writing from Nippon Paint and Color Vision teams.
                                </p>
                            </div>

                        </div>
                    </div>
                    <Footer />

                </div>
            </MetaWrapper>
        );
    }
}

export default TeamColorVision;
