import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"
import {
  Route,
  BrowserRouter as Router,
  Switch,
  HashRouter,
} from "react-router-dom"
import ThemelistingPage from "./Components/ThemelistingPage/ThemelistingPage"
import ColorVisionThemePage from "./Components/ColorVisionThemePage/ColorVisionThemePage"
import IndiaBloomPage from "./Components/IndiaBloomPage/IndiaBloomPage"
import ScrollToTop from "./Components/ScrollToTop"
import ColorVisionTeamPage from "./Components/ColorVisionTeamPage/ColorVisionTeamPage"
import TeamColorVision from "./Components/TeamColorVision/TeamColorVision"
import ForecastMainPage from "./Components/forecastConactForm/ForecastMainPage"
import Landingpage from "./Components/LandingPage/Landingpage"
import { ApolloProvider } from "@apollo/client"
import client from "./Apollo/apolloClient"

// import Test from "./test";

import ReactGA from "react-ga"
ReactGA.initialize("UA-154637654-1")

const routing = (
  <Router>
    <ScrollToTop />
    <Route exact path="/" component={App} />
    <Route
      exact
      path="/forecasts/:region/:regionid"
      component={ThemelistingPage}
    />
    <Route
      exact
      path="/theme/:theme/:themeid"
      component={ColorVisionThemePage}
    />
    <Route exact path="/IndiaBloomPage" component={IndiaBloomPage} />
    <Route exact path="/OurTeamPage" component={ColorVisionTeamPage} />
    <Route exact path="/team-color-vision" component={TeamColorVision} />
    <Route exact path="/forecast-form" component={ForecastMainPage} />
    <Route exact path="/landing-page" component={Landingpage} />
    {/*<Route exact path="/test" component={Test} />*/}
  </Router>
)

ReactDOM.render(
  <ApolloProvider client={client}>{routing}</ApolloProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
