import { gql } from '@apollo/client';

export const TEAM_LIST_QUERY = gql`
  query ColorVisionTeamPageQuery {
    teamList {
      id
      name
      role
      place
      description
      userId
      url
    }
  }
`;