import React from "react"
import "./HomePage.css"
import Container from "react-bootstrap/Container"
import NipponLogo from "./../../Assets/Images/nippon_logo.svg"
import Book from "./../../Assets/Images/book.png"
import color_solor from "./../../Assets/Images/color_solor.png"
import color_box from "./../../Assets/Images/color-box.png"
import Button from "react-bootstrap/Button"
import $ from "jquery"
import { Col } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import {
  faCloudDownloadAlt,
  faPauseCircle,
  faPlayCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { withRouter } from "react-router"
import ScrollToTop from "./../ScrollToTop"
// import CreateTokenMutation from './../../mutation/CreateTokenMutation';
// import {fetchQuery} from 'relay-runtime';
// import graphql from 'babel-plugin-relay/macro'
// import environment from "../../Environment";
// import CreateFeedbackFormMutation from "../../mutation/CreateFeedbackFormMutation";
import cogoToast from "cogo-toast"
// import ForecastFormPage from "../ForecastFormPage/ForecastFormPage";journeyThumb
// import Video from '../../Assets/Images/VID-20191214-WA0003.mp4'
// import Mobile_Video from '../../Assets/Images/abstract video.mp4'
import journeyThumb from "../../Assets/Images/journey-thumb.jpg"
import abstractVideo from "./../../Assets/Images/new-banner-video.mp4"
import abstractVideoMD from "./../../Assets/Images/new-banner-video-md.mp4"
import journey from "./../../Assets/Images/Color-Vision-Final.m4v"
// import process_image from '../../Assets/Images/MicrosoftTeams-image.JPG';
import Media from "react-media"
import FileSaver from "file-saver"
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import client from "../../Apollo/apolloClient"
import { REGIONS_LIST_QUERY } from "../../Apollo/queries/RegionListQuery"
import { CREATE_TOKEN_MUTATION } from "../../Apollo/mutations/CreateTokenMutation"
import { CREATE_FORECAST_FORM_MUTATION } from "../../Apollo/mutations/CreateForecastFormMutation"

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlayed: false,
      IndiaBloomModalShow: false,
      regionList: [],
      Name: "",
      Email: "",
      PhoneNumber: "",
      Profession: "",
      Message: "",
      EmailFieldError: "",
      NumberFieldError: "",
      CityError: "",
    }
    this.openIndiaBloom = this.openIndiaBloom.bind(this)
  }

  componentDidMount() {
    $("#section1").on("click", function (e) {
      e.preventDefault()
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        500
      )
      return false
    })
    let self = this

    $("#exploreIndia").click(function () {
      $(this).toggleClass("is-active")

      self.setState({
        IndiaBloomModalShow: !self.state.IndiaBloomModalShow,
      })
      if (self.state.IndiaBloomModalShow) {
        document.getElementById("HeaderNav").style.zIndex = "10"
      } else {
        document.getElementById("HeaderNav").style.zIndex = "1200"
      }
    })

    let userData = {
      username: "deep",
      password: "5",
    }
    let scope = this

    client
      .mutate({
        mutation: CREATE_TOKEN_MUTATION,
        variables: userData,
      })
      .then((response) => {
        const { data } = response
        if (data && data.tokenAuth && data.tokenAuth.token) {
          localStorage.setItem("User_Token", data.tokenAuth.token)
          this.getRegionList()
        } else {
          // Handle invalid response or missing token
        }
      })
      .catch((error) => {
        console.error("Error creating token:", error)
        // Handle error here, e.g., show error message
      })
  }

  getRegionList = () => {
    client
      .query({
        query: REGIONS_LIST_QUERY,
      })
      .then((response) => {
        if (response.data && response.data.regionsList) {
          this.setState({ regionList: response.data.regionsList })
        } else {
          cogoToast.error("There is no data", { position: "top-center" })
        }
      })
      .catch((error) => {
        console.error("Error fetching regions list:", error)
        cogoToast.error("Failed to fetch data", { position: "top-center" })
      })
  }
  gotoThemeListPage = (e, regionid, region) => {
    this.props.history.push("/forecasts/" + region + "/" + regionid)

    // let regionList = this.state.regionList;
    // for (let i = 0; i < regionList.length; i++) {
    //     if (region === regionList[i].regionId) {
    //
    //         this.props.history.push('/forecast/' + region + '/'+regionid, {region: regionList[i]});
    //
    //     } else {
    //
    //     }
    //
    // }
  }

  closeModal() {
    this.setState({
      IndiaBloomModalShow: false,
    })
    document.getElementById("HeaderNav").style.zIndex = "1200"
  }

  playPause() {
    var myVideo = document.getElementById("myVideo1")
    if (myVideo.paused) {
      myVideo.play()
      this.setState({
        isPlayed: true,
      })
    } else {
      myVideo.pause()
      this.setState({
        isPlayed: false,
      })
    }
  }

  openIndiaBloom() {
    this.setState({
      IndiaBloomModalShow: true,
    })

    document.getElementById("HeaderNav").style.zIndex = "10"
  }

  sendFeedback = () => {
    debugger
    let Name = this.state.Name
    let mobileNumber = this.state.PhoneNumber
    let Email = this.state.Email
    let Profession = this.state.Profession
    let City = this.state.City
    let Company = this.state.Company

    // let Message = this.state.Message;
    if (Name === "") {
      this.setState({
        emptyField: "Enter your name",
      })
    } else if (mobileNumber === "") {
      this.setState({
        emptyField: "Enter your mobile number",
      })
    } else if (Email === "") {
      this.setState({
        emptyField: "Enter your email id",
      })
    } else if (Profession === "") {
      this.setState({
        emptyField: "Enter your profession",
      })
    } else if (City === "" || City === undefined || City === null) {
      this.setState({
        emptyField: "Enter your city",
      })
    } else {
      this.setState({
        emptyField: "",
      })
      const UserFormData = {
        email: Email,
        // message: Message,
        mobileNumber: mobileNumber,
        name: Name,
        profession: Profession,
        city: City,
        companyName: Company,
      }
      let self = this

      // CreateFeedbackFormMutation(UserFormData, function (response) {
      //
      //     if (response.createFeedback !== null) {
      //
      //         cogoToast.success("ThankYou For Your Feedback", {position: 'top-center'});
      //         self.setState({
      //             Name: '',
      //             Email: '',
      //             PhoneNumber: '',
      //             Profession:''
      //         });
      //
      //     } else {
      //         cogoToast.error("Please try again", {position: 'top-center'});
      //     }
      // }, function (err) {
      //
      //
      // });
      client
        .mutate({
          mutation: CREATE_FORECAST_FORM_MUTATION,
          variables: UserFormData,
        })
        .then((response) => {
          if (response.data && response.data.downloadPdf !== null) {
            cogoToast.success("Downloading...", { position: "top-center" })
            this.setState({
              Name: "",
              Email: "",
              PhoneNumber: "",
              Profession: "",
              City: "",
              Company: "",
            })
            FileSaver.saveAs(
              "https://www.nipponpaintcolorvision.com/asset/Nippon_WebBook.pdf",
              "Nippon_WebBook.pdf"
            )
          } else {
            cogoToast.error("Please try again", { position: "top-center" })
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          cogoToast.error("Failed to fetch data", { position: "top-center" })
        })
    }
  }

  handleChangeInput = (event) => {
    if (event.target.name === "Name") {
      this.setState({
        Name: event.target.value,
        emptyField: "",
      })
    } else if (event.target.name === "phoneNumber") {
      const reg = /^[1-9]{1}[0-9]{9}$/
      let NumlValid = reg.test(event.target.value)

      let fieldValidationErrors = NumlValid ? "" : "Enter 10 digit number"

      if (event.target.value.length < 10 || event.target.value.length > 10) {
        this.setState({
          NumberFieldError: "Enter 10 digit number",
          PhoneNumber: event.target.value,
          emptyField: "",
        })
      } else {
        this.setState({
          NumberFieldError: fieldValidationErrors,
          PhoneNumber: event.target.value,
          emptyField: "",
        })
      }
    } else if (event.target.name === "Email") {
      let emailValid = event.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      )
      let fieldValidationErrors = emailValid ? "" : "Enter a valid email id"
      this.setState({
        EmailFieldError: fieldValidationErrors,
        Email: event.target.value,
        emptyField: "",
      })
    } else if (event.target.name === "Message") {
      this.setState({
        Message: event.target.value,
        emptyField: "",
      })
    } else if (event.target.name === "Profession") {
      this.setState({
        Profession: event.target.value,
      })
    } else if (event.target.name === "Company") {
      this.setState({
        Company: event.target.value,
      })
    } else if (event.target.name === "City") {
      this.setState({
        CityError: event.target.value === "" ? "Enter your city" : "",
        City: event.target.value,
        emptyField: "",
      })
    }
  }
  goToTheme = (e, regionid, region) => {
    this.props.history.push("/forecasts/" + region + "/" + regionid)
  }
  goToForcast = (e) => {
    this.props.history.push("/forecast-form")
  }

  render() {
    return (
      <>
        <ScrollToTop />
        <Container fluid={true} className="p-0">
          <Header
            openIndiaBloomModal={this.openIndiaBloom}
            aaaa={this.state.Name}
          />
          {/*<ForecastFormPage/>*/}
          <div className="buttonDiv">
            <Button
              className="forecastformBtn"
              onClick={() => this.goToForcast()}
            >
              <FontAwesomeIcon
                icon={faCloudDownloadAlt}
                style={{ marginRight: "10px" }}
              />
              DOWNLOAD / BUY NOW
            </Button>
          </div>
          <section className="HomePageBg d-flex text-center align-self-center justify-content-center">
            <Media
              query={{ maxWidth: "700px" }}
              render={() => (
                <div className="embed-responsive embed-responsive-4by3">
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    width="100%"
                    className="embed-responsive-video"
                  >
                    <source src={abstractVideoMD} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              )}
            />

            <Media
              query={{ minWidth: "700px" }}
              render={() => (
                <div className="embed-responsive embed-responsive-16by9">
                  <video autoPlay muted loop width="100%">
                    <source src={abstractVideo} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              )}
            />

            {/*<div className="videoContent text-center my-auto p-5">*/}
            {/*    <img alt="logo" className="img-fluid" src={NipponLogo}/>*/}
            {/*    <br/>*/}
            {/*    <img className="img-fluid m-4" src={Colorvision} alt="ColorVision"/>*/}
            {/*    <br/>*/}
            {/*    <Button className="experienceBtn  pt-2 pb-2 pl-4 pr-4"><a id="section1" href="#section02"*/}
            {/*                                                              style={{*/}
            {/*                                                                  color: 'inherit',*/}
            {/*                                                                  textDecoration: "none"*/}
            {/*                                                              }}><span*/}
            {/*        style={{color: ' #3C336D'}}>EXPERIENCE COLOR</span></a></Button>*/}
            {/*</div>*/}
          </section>
          {/*<section >*/}
          <div
            id="section02"
            className="d-flex text-center align-items-center justify-content-center"
          >
            {/*<div className="overlayImg"/>*/}
            {/*<img className="img-fluid" src={IndiaBloom} />*/}

            <div className="videoContent text-center my-auto p-5">
              {/*<div className="text-justify">*/}
              <p className="indiaBloomSubTitle m-0">
                Colors are omnipresent in our lives and are part of everything
                that comes with living. In a culturally diversified and plural
                country like India, colors have always been a crucial thread
                binding our emotional, social and cultural fabric. As with the
                Domino Effect, this could consequently affect our hormonal surge
                which would then impact our bio-behaviour.{" "}
              </p>
              {/*<p className="indiaBloomSubTitle m-0">Meanwhile with juxtaposed carnival of paradox, we will*/}
              {/*    bloom in plurality as the petals of a wild flower while we become empathetic to each*/}
              {/*    other's identity and love ourselves.</p>*/}
              {/*<p className="indiaBloomSubTitle m-0">Let’s celebrate the plurality, enjoy the bloom.</p>*/}
              {/*</div>*/}
              <br />
              <Button
                className="experienceBtn pt-2 pb-2 pl-4 pr-4"
                id="exploreIndia"
              >
                <span style={{ color: " #3C336D" }}>READ MORE</span>
              </Button>
            </div>
          </div>
          {/*</section>*/}
          <Row className="m-0">
            <Col
              md={6}
              xs={12}
              className="YouthRegionBg p-0 d-flex text-center align-items-center justify-content-center"
            >
              <div className="videoContent text-center my-auto p-3">
                <p className="indiaBloomSubTitle m-0">Colors of</p>
                <p className="indiaBloomTitle m-0">YOUth</p>
                {/*<p className="indiaBloomSubTitle m-0">This section home color forecast exhibits the*/}
                {/*    theme to be prevalent north India</p>*/}
                <br />
                <Button
                  className="experienceBtn  pt-2 pb-2 pl-4 pr-4"
                  onClick={(event) => this.gotoThemeListPage(event, 1, "youth")}
                >
                  <span style={{ color: " #3C336D" }}>EXPLORE</span>
                </Button>
              </div>
            </Col>
            <Col md={6} xs={12} className="p-0">
              <Col
                md={12}
                xs={12}
                className="NorthRegionBg p-0 d-flex text-center align-items-center justify-content-center"
              >
                <div className="videoContent text-center my-auto p-3">
                  <p className="indiaBloomSubTitle m-0">Colors of</p>
                  <p className="indiaBloomTitle m-0">NORTH</p>
                  {/*<p className="indiaBloomSubTitle m-0">This section home color forecast exhibits the*/}
                  {/*    theme to be prevalent south India</p>*/}
                  <br />
                  <Button
                    className="experienceBtn  pt-2 pb-2 pl-4 pr-4"
                    onClick={(event) =>
                      this.gotoThemeListPage(event, 2, "north")
                    }
                  >
                    <span style={{ color: " #3C336D" }}>EXPLORE</span>
                  </Button>
                </div>
              </Col>
              <Col
                md={12}
                xs={12}
                className="SothRegionBg p-0 d-flex text-center align-items-center justify-content-center"
              >
                <div
                  className="videoContent text-center my-auto p-3"
                  style={{ position: "absolute" }}
                >
                  <p className="indiaBloomSubTitle m-0">Colors of</p>
                  <p className="indiaBloomTitle m-0">SOUTH</p>
                  {/*<p className="indiaBloomSubTitle m-0">This section home color forecast exhibits the*/}
                  {/*theme to be prevalent south India</p>*/}
                  <br />
                  <Button
                    className="experienceBtn  pt-2 pb-2 pl-4 pr-4"
                    onClick={(event) =>
                      this.gotoThemeListPage(event, 3, "south")
                    }
                  >
                    <span style={{ color: " #3C336D" }}>EXPLORE</span>
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
          <div className="col-12 text-center pt-5 pb-2">
            <p className="ContactFormTitle">The Journey</p>
            {/*<p className="">114 catalysts, influencers, and domain experts. 6 focus groups, round tables,*/}
            {/*    and color workshops. Copyright protected methodology and system. Over 18 months of research.*/}
            {/*    15 themes. 3 categories. 1 country! Catch a glimpse on how we went about the Color Forecasts*/}
            {/*    for 2020 and 2021.</p>*/}
            <div className="container justify-content-cente journey_content"></div>
          </div>
          <section className="banner embed-responsive-item ">
            <video
              className="hidden-sm-down color_process_video_secion"
              poster={journeyThumb}
              controls={true}
            >
              {/*<source src="http://techslides.com/demos/sample-videos/small.webm" type="video/webm" />*/}
              <source src={journey} type="video/mp4" />
            </video>
            {/*<a className="btn btn-primary btn-lg float-right" href="#" role="button">Enquire Now</a>*/}
          </section>

          {/* <section className="d-flex text-center align-items-center justify-content-center mt-n5">*/}

          {/*     <div className="embed-responsive embed-responsive-16by9 mt-n5 ">*/}
          {/*         <video id="myVideo1" className="embed-responsive-item" controls={true}>*/}
          {/*             <source*/}
          {/*                 src="https://www.nipponpaintcolorvision.com/asset/CV.m4v"*/}
          {/*                 type="video/mp4"/>*/}
          {/*             Your browser does not support HTML5 video.*/}
          {/*         </video>*/}
          {/*     </div>*/}
          {/*/!*     <div className="videoContent text-center my-auto p-5">*!/*/}
          {/*/!*         <p className="indiaBloomTitle m-0">The Journey</p>*!/*/}
          {/*/!*         /!*<p className="indiaBloomSubTitle m-0">This section home color forecast exhibits the theme to*!/*!/*/}
          {/*/!*         /!*    be prevalent south India</p>*!/*!/*/}
          {/*/!*         <br/>*!/*/}
          {/*/!*         <span onClick={() => this.playPause()}>*!/*/}
          {/*/!*        <FontAwesomeIcon icon={this.state.isPlayed ? faPauseCircle : faPlayCircle}*!/*/}
          {/*/!*                         style={{color: "#fff", fontSize: '45px'}}/>*!/*/}
          {/*/!*</span>*!/*/}
          {/*/!*     </div>*!/*/}
          {/* </section>*/}
          <section className="buy-now ">
            <div className="buy-now-section">
              <p className="physical-title-section">
                Get physical copy of the book
              </p>
              <Button
                className="buy-now-cta"
                onClick={() => this.goToForcast()}
              >
                BUY NOW
              </Button>
            </div>
            <div
              className="book-img"
              style={{ position: "absolute", left: "-0%", bottom: "-50%" }}
            >
              <img
                src={Book}
                alt={""}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "300px",
                }}
              />
            </div>
          </section>
          <Row className="m-0">
            {/*<Col md={5}*/}
            {/*     className="Contact_bg p-0 d-flex text-center align-items-center justify-content-center ">*/}
            {/*    <div className="videoContent text-center my-auto p-5 w-75">*/}
            {/*        /!*<p className="indiaBloomTitle m-0">Upcoming Events</p>*!/*/}

            {/*    </div>*/}
            {/*</Col>*/}
            <Col md={12} className="p-0 d-flex justify-content-center ">
              <div className="p-5">
                <p className="ContactFormTitle m-0">
                  Download Color Vision 2024-2025 E-book
                  {/*To know more about color psychology register for*/}
                  {/*Colorevolv, a color immersive workshop*/}
                  {/*Fill out the form and we will get back to you*/}
                </p>

                <hr
                  style={{
                    border: " 1px solid #304CB9",
                    opacity: " 1",
                    width: "90px",
                  }}
                  className="float-left mt-0"
                />
                <br />
                {/*<p className=" m-0">Learn more about color psychology at our color immersive workshop.</p>*/}
                <Form className="mt-4 mb-4">
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="Name"
                      placeholder="Your Name"
                      className="ContactField"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Name}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicNumber">
                    <Form.Control
                      type="number"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      onChange={(event) => this.handleChangeInput(event)}
                      className="ContactField"
                      value={this.state.PhoneNumber}
                    />
                    {this.state.NumberFieldError !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.NumberFieldError}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      onChange={(event) => this.handleChangeInput(event)}
                      className="ContactField"
                      name="Email"
                      value={this.state.Email}
                    />
                    {this.state.EmailFieldError !== "" ? (
                      <p style={{ color: "red" }}>
                        {this.state.EmailFieldError}
                      </p>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="Profession"
                      placeholder="Your Profession"
                      className="ContactField"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Profession}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="Company"
                      placeholder="Your Company"
                      className="ContactField"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.Company}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      type="text"
                      name="City"
                      placeholder="Your City"
                      className="ContactField"
                      onChange={(event) => this.handleChangeInput(event)}
                      value={this.state.City}
                    />
                  </Form.Group>
                  {this.state.EmailFieldError !== "" ? (
                    <p style={{ color: "red" }}>{this.state.EmailFieldError}</p>
                  ) : null}
                  {/*<Form.Group controlId="exampleForm.ControlTextarea1">*/}
                  {/*    <Form.Control as="textarea" rows="3" placeholder="Message" name="Message"*/}
                  {/*                  onChange={(event) => this.handleChangeInput(event)}*/}
                  {/*                  value={this.state.Message}/>*/}

                  {/*</Form.Group>*/}
                  <Button
                    type="button"
                    className="w-100 rounded-0  submitBtn"
                    onClick={(event) => this.sendFeedback(event)}
                  >
                    DOWNLOAD PDF
                  </Button>
                  {this.state.emptyField !== "" ? (
                    <p style={{ color: "red" }}>{this.state.emptyField}</p>
                  ) : null}
                </Form>
              </div>
            </Col>
          </Row>

          <Modal
            size="lg"
            show={this.state.IndiaBloomModalShow}
            onHide={() =>
              this.setState({
                IndiaBloomModalShow: false,
              })
            }
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="indiaBloomModal pl-0"
            backdrop="static"
          >
            <div className="CloseBtn" onClick={() => this.closeModal()}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {/*<Modal.Header closeButton>*/}
            {/*</Modal.Header>*/}
            <Modal.Body className="p-0">
              <div className="indiaBloomBg" />
              <div className="text-center">
                <div className="indiaBloomModalArticle text-justify pl-lg-5 pr-lg-5 pt-4 pl-3 pr-3">
                  <p className="pl-lg-5 pr-lg-5">
                    Colors are omnipresent in our lives and are part of
                    everything that comes with living. In a culturally
                    diversified and plural country like India, colors have
                    always been a crucial thread binding our emotional, social
                    and cultural fabric. As with the Domino Effect, this could
                    consequently affect our hormonal surge which would then
                    impact our bio-behaviour.
                  </p>
                  <p className="pl-lg-5 pr-lg-5">
                    The Hypothalamus, a structure located deep within the brain,
                    experiences the impact of colors via signals from the
                    retinal ganglion cells. Each of these hues and shades
                    carries meta-tags with memories in the context of cultural,
                    social, emotional, and bio-behavioral reactions. A key point
                    to note here is that individual colors don’t express
                    significant meaning, only a group of colors do. This is why,
                    at Nippon Paint, we chose to emphasize color archetypes as
                    stories and themes. In this second edition of the Nippon
                    Paint India ColorVision 2024-2025, the forecast includes
                    color archetypes that were projected as key themes of 2024
                    and 2025.
                  </p>
                  <p className="pl-lg-5 pr-lg-5">
                    While the theme HEAL reflects the need for comfort and the
                    dreamlike surrealism of existing in a peaceful state, the
                    archetype BLOOMERS puts the focus on the steadily growing
                    popularity of ‘handmade and craft’ culture, where
                    individuals yearn to learn new skills that could bring
                    creative ideas to life. The mood of HIVE emphasizes on the
                    need for cohabitation and bonding, while the theme of
                    SOULCORE persuades one to seek the answer to a question that
                    has puzzled mankind, ‘Who am I?’ - an eternal enquiry to
                    find the ‘self’. The colors of these 4 key archetypes are
                    shown in Figure. 1.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={color_solor}
                      alt={""}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "600px",
                      }}
                    />
                  </div>
                  <p className="pl-lg-5 pr-lg-5">
                    Nippon Paint India ColorVision is the first ever zonal and
                    youth color forecast for India that recognizes and respects
                    the fact that color meaning and impact is geo-specific and
                    culture centric. The emotional facets and bio-behaviour of
                    the various color expressions vary according to the vast
                    geography of India. Therefore, in this edition of forecast
                    the archetypes are segmented according to their zonal
                    relevance. Additionally, the ‘Youth’ section highlights 12
                    distinct color archetypes, embodying the expressions and
                    moods of the Indian youth. These are thoughtfully
                    categorized under north, south and youth segments in this
                    edition. The unique ColorVision forecasting process spans
                    over 16 months and involves approximately 30,000 meta-tags —
                    color clues processed through multiple focus groups,
                    including invited influencers, color catalysts, domain
                    experts, and color practitioners. This year, more than 55
                    participants from Mumbai, Delhi, Chennai, Hyderabad, Pune,
                    Kolkata, Pondicherry, Bangalore, Ahmedabad, etc., were
                    engaged in various research progress levels, and some are
                    acknowledged at the end of this book as a token of gratitude
                    for their contributions. Nippon Paint India ColourVision's
                    color foresight system is based on the copyrighted method of
                    Dr. Kaustav SenGupta, the Lead Forecaster for Nippon Paint
                    ColorVision. The method incorporates a unique 'Color
                    Foresight Cube' (SenGupta, 2018), mapping color signifiers
                    through signals and drivers to indicate shifts and
                    understand plausible color directions based on emotional,
                    bio-behavioral, cultural, and social meta-tags. The 3Px3C
                    framework (SenGupta, 2018) forms the macro base for this
                    color mapping system. Dr. SenGupta's color foresight cube is
                    displayed in Figure. 2 for reference.{" "}
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={color_box}
                      alt={""}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "600px",
                      }}
                    />
                  </div>
                  <p className="pl-lg-5 pr-lg-5">
                    {" "}
                    Although this forecast primarily targets home color
                    (interior and exterior home walls, furnishings, décor,
                    accessories, tableware, lighting, etc.), it can be applied
                    to other product segments like personal goods, digital
                    electronics, FMCG, and automobiles, as these color
                    archetypes can impact various domains.
                  </p>
                  {/* <p className="pl-lg-5 pr-lg-5">
                    {" "}
                    Let’s celebrate the plurality and enjoy the BLOOM.
                  </p> */}

                  <p
                    className="ThemeExploreTitle p-3 m-0"
                    style={{
                      color: "#000",
                      fontFamily: '"Neo-Sans", sans-serif',
                    }}
                  >
                    Explore the Color Forecasts for India
                  </p>
                  <Row>
                    <Col md={1} />
                    <Col
                      md={3}
                      xs={12}
                      className=" ThemeExploreBg1 text-center m-lg-4 mt-4 "
                    >
                      {/*<img className="img-fluid" src={theme1} style={{opacity: '0.9'}}/>*/}
                      <div
                        className="carousel-caption"
                        style={{ top: "50%" }}
                        onClick={(event) => this.goToTheme(event, 1, "youth")}
                      >
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">Youth</p>
                      </div>
                    </Col>
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg2 text-center m-lg-4 mt-4 "
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div
                        className="carousel-caption"
                        style={{ top: "50%" }}
                        onClick={(event) => this.goToTheme(event, 2, "north")}
                      >
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">North</p>
                      </div>
                    </Col>
                    <Col
                      md={3}
                      xs={12}
                      className="ThemeExploreBg3 text-center m-lg-4 mt-4"
                    >
                      {/*<img className="img-fluid" src={theme2} style={{opacity: '0.9'}}/>*/}

                      <div
                        className="carousel-caption"
                        style={{ top: "50%" }}
                        onClick={(event) => this.goToTheme(event, 3, "south")}
                      >
                        <p className="indiaBloomSubTitle m-0">Colors of</p>
                        <p className="list-region_title">South</p>
                      </div>
                    </Col>
                    <Col md={1} />
                  </Row>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Footer />
        </Container>
      </>
    )
  }
}

export default withRouter(HomePage)
