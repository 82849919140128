import React from "react";
import './LandingPage.css';
import bgImage1 from '../../Assets/Images/forecastBg1.png';
import bgImage2 from '../../Assets/Images/forecastBg2.png';
import bgImage3 from '../../Assets/Images/forecastBg3.png';
import colorvisionLogo from '../../Assets/Images/ColorVision_Logo.svg'
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import cogoToast from "cogo-toast";
import {faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router";
import FileSaver from "file-saver";
import client from "../../Apollo/apolloClient";
import {CREATE_FORECAST_FORM_MUTATION} from "../../Apollo/mutations/CreateForecastFormMutation";


// var index = 0;
// const images = [{
//     img: bgImage1,
//     region: "South",
//     theme: "Blendendity",
//     colorName: "Greenway",
//     colorCode: "NP BGG 1624A",
//     color: "#438063",
// }, {
//     img: bgImage2,
//     region: "Youth",
//     theme: "Innocensuality",
//     colorName: "Dragon Fruit",
//     colorCode: "NP R 2350D",
//     color: '#c1454b'
// }, {
//     img: bgImage3,
//     region: "North",
//     theme: "LO+VE",
//     colorName: "Blue Mercury",
//     colorCode: "NP AC 2009A",
//     color: '#0d4398'
// }];

class Landingpage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            region: "South",
            theme: "Blendendity",
            colorName: "Greenway",
            colorCode: "NP BGG 1624A",
            color: "#438063",
            index: 0,
            Name: '',
            Email: '',
            PhoneNumber: '',
            Profession: '',
            EmailFieldError: '',
            NumberFieldError: '',
            emptyNameField: '',
            emptyField: '',
            emptyPhoneNumField: '',
            emptyEmailField: '',
            emptyProfessionField: '',
            isActive: true
        }
    }

    componentDidMount() {


    }



    handleChangeInput = (event) => {
        if (event.target.name === "Name") {
            this.setState({
                Name: event.target.value,
                emptyNameField: ''
            });
        } else if (event.target.name === "phoneNumber") {
            const reg = /^[1-9]{1}[0-9]{9}$/;
            let NumlValid = reg.test(event.target.value);

            let fieldValidationErrors = NumlValid ? '' : 'PhoneNumber is invalid';

            if (event.target.value.length < 10 || event.target.value.length > 10) {

                this.setState({
                    NumberFieldError: "PhoneNumber is invalid",
                    PhoneNumber: event.target.value,
                    emptyPhoneNumField: ''
                });
            } else {
                this.setState({
                    NumberFieldError: fieldValidationErrors,
                    PhoneNumber: event.target.value,
                    emptyPhoneNumField: ''
                });
            }


        } else if (event.target.name === "Email") {
            let emailValid = event.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            let fieldValidationErrors = emailValid ? '' : 'Email is invalid';
            this.setState({
                EmailFieldError: fieldValidationErrors,
                Email: event.target.value,
                emptyEmailField: ''
            })

        } else if (event.target.name === "Profession") {
            this.setState({
                Profession: event.target.value,
                emptyProfessionField: ''
            })
        }

    };

    DownloadPdf = () => {
        let Name = this.state.Name;
        let mobileNumber = this.state.PhoneNumber;
        let Email = this.state.Email;
        let Profession = this.state.Profession;
      
        if (Name === "") {
          this.setState({
            emptyNameField: "Enter Your Name"
          });
        } else if (Email === "") {
          this.setState({
            emptyEmailField: "Enter Your Email"
          });
        } else if (mobileNumber === "") {
          this.setState({
            emptyPhoneNumField: "Enter Your PhoneNumber"
          });
        } else if (Profession === "") {
          this.setState({
            emptyProfessionField: "Enter Your Profession"
          });
        } else {
          this.setState({
            emptyField: "",
            emptyNameField: "",
            emptyPhoneNumField: "",
            emptyEmailField: "",
            emptyProfessionField: "",
            isActive: false
          });
      
          const UserFormData = {
            email: Email,
            mobileNumber: mobileNumber,
            name: Name,
            profession: Profession
          };
      
          client
            .mutate({
              mutation: CREATE_FORECAST_FORM_MUTATION,
              variables: UserFormData
            })
            .then((response) => {
              if (response.data && response.data.downloadPdf !== null) {
                cogoToast.success("Downloading...", { position: "top-center" });
                this.setState({
                  Name: "",
                  Email: "",
                  PhoneNumber: "",
                  Profession: ""
                });
                FileSaver.saveAs(
                  "https://www.nipponpaintcolorvision.com/asset/Nippon_WebBook.pdf",
                  "Nippon_WebBook.pdf"
                );
              } else {
                cogoToast.error("Please try again", { position: "top-center" });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              cogoToast.error("Failed to fetch data", { position: "top-center" });
            });
        }
      };

    goToHome() {
        this.props.history.push("/");
    }

    render() {

        const {region, theme, color, colorName, colorCode, index} = this.state;
        if (this.state.isActive === false) {

            document.getElementById("ContactFormBtn").disabled = true;
            document.getElementById("ContactFormBtn").style.cursor = "not-allowed";
            document.getElementById("ContactFormBtn").style.backgroundColor = "#b1adad";
            document.getElementById("ContactFormBtn").style.border = "2px solid #b1adad";

        }
        return (
            <div className="landingpage_Contactpage" id="background">
                <div className="Conatactform-Container">
                    <div className="contact-header">
                        <img src={colorvisionLogo} className="contact_Logo" onClick={() => this.goToHome()}/>
                        {/*<p className="header_text">Colors of {region} - {theme}</p>*/}
                    </div>
                    <Row className="m-0">
                        <Col md={6} xs={12} className="p-0 landingColumn1">
                            <div className="form-Column">
                                <p className="Forecastformtitle">Access India's First Zone Specific Color
                                    Forecast</p>
                                <Form className="landingForm text-left">
                                    <Form.Group controlId="formBasicName" className="mb-4">
                                        <Form.Label className="label-name">Full Name</Form.Label>
                                        <Form.Control type="text" name="Name" placeholder="John Doe"

                                                      onChange={(event) => this.handleChangeInput(event)}
                                                      value={this.state.Name}
                                        />
                                        {this.state.emptyNameField !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.emptyNameField}</p> : null}

                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="mb-4">
                                        <Form.Label className="label-name">Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="john@example.com"
                                                      onChange={(event) => this.handleChangeInput(event)}
                                                      name="Email"
                                                      value={this.state.Email}
                                        />
                                        {this.state.EmailFieldError !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.EmailFieldError}</p> : null}
                                        {this.state.emptyEmailField !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.emptyEmailField}</p> : null}

                                    </Form.Group>
                                    <Form.Group controlId="formBasicNumber" className="mb-4">
                                        <Form.Label className="label-name">Mobile Number</Form.Label>
                                        <Form.Control type="number" placeholder="9876543210" name="phoneNumber"
                                                      onChange={(event) => this.handleChangeInput(event)}

                                                      value={this.state.PhoneNumber}
                                        />
                                        {this.state.NumberFieldError !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.NumberFieldError}</p> : null}
                                        {this.state.emptyPhoneNumField !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.emptyPhoneNumField}</p> : null}
                                    </Form.Group>
                                    <Form.Group controlId="formBasicName" className="mb-4">
                                        <Form.Label className="label-name">Profession</Form.Label>
                                        <Form.Control type="text" name="Profession" placeholder=""

                                                      onChange={(event) => this.handleChangeInput(event)}
                                                      value={this.state.Profession}
                                        />
                                        {this.state.emptyProfessionField !== '' ?
                                            <p style={{
                                                color: "red", fontSize: '14px',
                                                marginBottom: '0px'
                                            }}>{this.state.emptyProfessionField}</p> : null}
                                    </Form.Group>
                                    <Button type="button" id="ContactFormBtn"
                                            className="w-100 rounded-0  forecastContactformSubmitBtn  mt-3 mb-3"
                                            onClick={(event) => this.DownloadPdf(event)}
                                    >
                                        Download Free Colorvision 2020 E-Book
                                    </Button>
                                    {/*{this.state.emptyField !== '' ?*/}
                                    {/*    <p style={{color: 'red'}}>{this.state.emptyField}</p> : null}*/}
                                </Form>
                            </div>

                        </Col>
                        <Col md={6} xs={12} style={{minHeight: '85vh'}} className="landingColumn2">
                            <div className="bg_2020_forecast"/>

                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


export default withRouter(Landingpage)
