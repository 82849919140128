import React from "react"
import "./ForecastContactForm.css"
import bgImage1 from "../../Assets/Images/forecastBg1.jpg"
import bgImage2 from "../../Assets/Images/forecastBg2.png"
import bgImage3 from "../../Assets/Images/forecastBg3.jpg"
import colorvisionLogo from "../../Assets/Images/ColorVision_Logo.svg"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import cogoToast from "cogo-toast"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withRouter } from "react-router"
import FileSaver from "file-saver"
import client from "../../Apollo/apolloClient"
import { CREATE_FORECAST_FORM_MUTATION } from "../../Apollo/mutations/CreateForecastFormMutation"
import Footer from "../Footer/Footer"

var index = 0
const images = [
  {
    img: bgImage1,
    region: "South",
    theme: "Hopelicious",
    colorName: "Water Fountain",
    colorCode: "NP PB 2870 T",
    color: "#8ebee5",
  },
  {
    img: bgImage2,
    region: "South",
    theme: "Hopelicious",
    colorName: "Lucky Charm",
    colorCode: "NP YO 1135 D",
    color: "#fcd768",
  },
  {
    img: bgImage3,
    region: "South",
    theme: "Hopelicious ",
    colorName: "Latino Burst",
    colorCode: "NP BGG 2584 A",
    color: "#b7c436",
  },
]

class ForecastContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      region: "South",
      theme: "Hopelicious",
      colorName: "Water Fountain",
      colorCode: "NP PB 2870 T",
      color: "#8ebee5",
      index: 0,
      Name: "",
      Email: "",
      PhoneNumber: "",
      Profession: "",
      City: "",
      Company: "",
      EmailFieldError: "",
      NumberFieldError: "",
      emptyNameField: "",
      emptyField: "",
      emptyPhoneNumField: "",
      emptyEmailField: "",
      emptyProfessionField: "",
      emptyCityField: "",
      isActive: true,
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.changeBg()
    }, 3000)
  }

  changeBg() {
    var doc = document.getElementById("background")
    this.setState({
      region: images[index].region,
      theme: images[index].theme,
      colorName: images[index].colorName,
      colorCode: images[index].colorCode,
      color: images[index].color,
      index: index,
    })
    debugger
    doc.style.backgroundImage = `url(${images[index].img})`
    doc.style.backgroundSize = "cover"
    doc.style.backgroundRepeat = "no-repeat"
    doc.style.height = "auto"
    index = (index + 1) % images.length
  }

  PrevChangeBg = () => {
    let currentindex
    if (this.state.index !== 0) {
      currentindex = this.state.index - 1
    } else {
      currentindex = 2
    }

    // if (this.state.index !== 0) {
    let doc = document.getElementById("background")
    this.setState({
      region: images[currentindex].region,
      theme: images[currentindex].theme,
      colorName: images[currentindex].colorName,
      colorCode: images[currentindex].colorCode,
      color: images[currentindex].color,
      index: currentindex,
    })
    doc.style.backgroundImage = "url(" + images[currentindex].img + ")"
    doc.style.backgroundSize = "cover"
    doc.style.backgroundRepeat = "no-repeat"
    doc.style.height = "auto"
    index = currentindex % images.length
    // }
    // else {
    //     index = (this.state.index) % images.length;
    // }
  }
  NextChangeBg = () => {
    let currentindex = this.state.index
    let doc = document.getElementById("background")
    this.setState({
      region: images[index].region,
      theme: images[index].theme,
      colorName: images[index].colorName,
      colorCode: images[index].colorCode,
      color: images[index].color,
      index: index,
    })
    doc.style.backgroundImage = "url(" + images[index].img + ")"
    doc.style.backgroundSize = "cover"
    doc.style.backgroundRepeat = "no-repeat"
    doc.style.height = "auto"
    index = (currentindex + 1) % images.length
  }

  handleChangeInput = (event) => {
    if (event.target.name === "Name") {
      this.setState({
        Name: event.target.value,
        emptyNameField: "",
      })
    } else if (event.target.name === "phoneNumber") {
      const reg = /^[1-9]{1}[0-9]{9}$/
      let NumlValid = reg.test(event.target.value)

      let fieldValidationErrors = NumlValid ? "" : "PhoneNumber is invalid"

      if (event.target.value.length < 10 || event.target.value.length > 10) {
        this.setState({
          NumberFieldError: "PhoneNumber is invalid",
          PhoneNumber: event.target.value,
          emptyPhoneNumField: "",
        })
      } else {
        this.setState({
          NumberFieldError: fieldValidationErrors,
          PhoneNumber: event.target.value,
          emptyPhoneNumField: "",
        })
      }
    } else if (event.target.name === "Email") {
      let emailValid = event.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      )
      let fieldValidationErrors = emailValid ? "" : "Email is invalid"
      this.setState({
        EmailFieldError: fieldValidationErrors,
        Email: event.target.value,
        emptyEmailField: "",
      })
    } else if (event.target.name === "Profession") {
      this.setState({
        Profession: event.target.value,
        emptyProfessionField: "",
      })
    } else if (event.target.name === "City") {
      this.setState({
        City: event.target.value,
        emptyCityField: "",
      })
    } else if (event.target.name === "Company") {
      this.setState({
        Company: event.target.value,
        emptyCompanyField: "",
      })
    }
  }
  DownloadPdf = () => {
    let Name = this.state.Name
    let mobileNumber = this.state.PhoneNumber
    let Email = this.state.Email
    let Profession = this.state.Profession
    let City = this.state.City
    let Company = this.state.Company

    if (Name === "") {
      this.setState({
        emptyNameField: "Enter Your Name",
      })
    } else if (Email === "") {
      this.setState({
        emptyEmailField: "Enter Your Email",
      })
    } else if (mobileNumber === "") {
      this.setState({
        emptyPhoneNumField: "Enter Your PhoneNumber",
      })
    } else if (Profession === "") {
      this.setState({
        emptyProfessionField: "Enter Your Profession",
      })
    } else if (City === "" || City === null || City === undefined) {
      this.setState({
        emptyCityField: "Enter Your City",
      })
    } else {
      this.setState({
        emptyField: "",
        emptyNameField: "",
        emptyPhoneNumField: "",
        emptyEmailField: "",
        emptyProfessionField: "",
        emptyCityField: "",
        isActive: false,
      })

      const UserFormData = {
        email: Email,
        mobileNumber: mobileNumber,
        name: Name,
        profession: Profession,
        city: City,
        companyName: Company,
      }

      client
        .mutate({
          mutation: CREATE_FORECAST_FORM_MUTATION,
          variables: UserFormData,
        })
        .then((response) => {
          if (response.data && response.data.downloadPdf !== null) {
            cogoToast.success("Downloading...", { position: "top-center" })
            this.setState({
              Name: "",
              Email: "",
              PhoneNumber: "",
              Profession: "",
              City: "",
              Company: "",
            })
            FileSaver.saveAs(
              "https://www.nipponpaintcolorvision.com/asset/Nippon_WebBook.pdf",
              "Nippon_WebBook.pdf"
            )
          } else {
            cogoToast.error("Please try again", { position: "top-center" })
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          cogoToast.error("Failed to fetch data", { position: "top-center" })
        })
    }
  }
  Buynow = () => {
    let Name = this.state.Name
    let mobileNumber = this.state.PhoneNumber
    let Email = this.state.Email
    let Profession = this.state.Profession
    let City = this.state.City
    let Company = this.state.Company

    if (Name === "") {
      this.setState({
        emptyNameField: "Enter Your Name",
      })
    } else if (Email === "") {
      this.setState({
        emptyEmailField: "Enter Your Email",
      })
    } else if (mobileNumber === "") {
      this.setState({
        emptyPhoneNumField: "Enter Your PhoneNumber",
      })
    } else if (Profession === "") {
      this.setState({
        emptyProfessionField: "Enter Your Profession",
      })
    } else if (City === "" || City === null || City === undefined) {
      this.setState({
        emptyCityField: "Enter Your City",
      })
    } else {
      this.setState({
        emptyField: "",
        emptyNameField: "",
        emptyPhoneNumField: "",
        emptyEmailField: "",
        emptyProfessionField: "",
        emptyCityField: "",
        isActive: false,
      })

      const UserFormData = {
        email: Email,
        mobileNumber: mobileNumber,
        name: Name,
        profession: Profession,
        city: City,
        companyName: Company,
        isInterestedInBuying: true,
      }

      client
        .mutate({
          mutation: CREATE_FORECAST_FORM_MUTATION,
          variables: UserFormData,
        })
        .then((response) => {
          if (
            response.data &&
            response.data.downloadPdf !== null &&
            response.data.message
          ) {
            cogoToast.success(response.data.message, { position: "top-center" })
            this.setState({
              Name: "",
              Email: "",
              PhoneNumber: "",
              Profession: "",
              City: "",
              Company: "",
            })
          } else {
            cogoToast.error("Please try again", { position: "top-center" })
          }
        })
        .catch((error) => {
          console.error("Error:", error)
          cogoToast.error("Failed to fetch data", { position: "top-center" })
        })
    }
  }
  goToHome() {
    this.props.history.push("/")
  }

  render() {
    const { region, theme, color, colorName, colorCode, index } = this.state
    if (this.state.isActive === false) {
      document.getElementById("ContactFormBtn").disabled = true
      document.getElementById("ContactFormBtn").style.cursor = "not-allowed"
      document.getElementById("ContactFormBtn").style.backgroundColor =
        "#b1adad"
      document.getElementById("ContactFormBtn").style.border =
        "2px solid #b1adad"
    }
    return (
      <>
        <div className="forecast_contactform" id="background">
          <div className="Conatactform-Container">
            <div className="contact-header">
              <img
                src={colorvisionLogo}
                alt="logo"
                className="contact_Logo"
                onClick={() => this.goToHome()}
              />
              <p className="header_text">
                Colors of {region} - {theme}
              </p>
            </div>
            <Row className="m-0">
              <Col md={6} xs={12} className="p-0">
                <div className="form-Column">
                  <p className="Forecastformtitle">
                    Get Access to India's First Zone Specific Color Forecast
                  </p>
                  <Form className=" text-left">
                    <Form.Group controlId="formBasicName" className="mb-4">
                      <Form.Label className="label-name">Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="Name"
                        placeholder="John Doe"
                        onChange={(event) => this.handleChangeInput(event)}
                        value={this.state.Name}
                      />
                      {this.state.emptyNameField !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.emptyNameField}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label className="label-name">
                        Email Address
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="john@example.com"
                        onChange={(event) => this.handleChangeInput(event)}
                        name="Email"
                        value={this.state.Email}
                      />
                      {this.state.EmailFieldError !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.EmailFieldError}
                        </p>
                      ) : null}
                      {this.state.emptyEmailField !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.emptyEmailField}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicNumber" className="mb-4">
                      <Form.Label className="label-name">
                        Mobile Number
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="9876543210"
                        name="phoneNumber"
                        onChange={(event) => this.handleChangeInput(event)}
                        value={this.state.PhoneNumber}
                      />
                      {this.state.NumberFieldError !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.NumberFieldError}
                        </p>
                      ) : null}
                      {this.state.emptyPhoneNumField !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.emptyPhoneNumField}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId="formBasicName" className="mb-4">
                      <Form.Label className="label-name">Profession</Form.Label>
                      <Form.Control
                        type="text"
                        name="Profession"
                        placeholder=""
                        onChange={(event) => this.handleChangeInput(event)}
                        value={this.state.Profession}
                      />
                      {this.state.emptyProfessionField !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.emptyProfessionField}
                        </p>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="formBasicName" className="mb-4">
                      <Form.Label className="label-name">Company</Form.Label>
                      <Form.Control
                        type="text"
                        name="Company"
                        placeholder=""
                        onChange={(event) => this.handleChangeInput(event)}
                        value={this.state.Company}
                      />
                      {this.state.emptyCompanyField !== "" ? (
                        <p style={{ color: "red" }}>
                          {this.state.emptyCompanyField}
                        </p>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="formBasicName" className="mb-4">
                      <Form.Label className="label-name">City</Form.Label>
                      <Form.Control
                        type="text"
                        name="City"
                        placeholder=""
                        onChange={(event) => this.handleChangeInput(event)}
                        value={this.state.City}
                      />
                      {this.state.emptyCityField !== "" ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginBottom: "0px",
                          }}
                        >
                          {this.state.emptyCityField}
                        </p>
                      ) : null}
                    </Form.Group>

                    <div className="main-buy-now-section">
                      <Button
                        type="button"
                        id="ContactFormBtn"
                        className="w-100   forecastContactformSubmitBtn"
                        onClick={(event) => this.DownloadPdf(event)}
                        style={{ borderRight: "2px solid white" }}
                      >
                        Download Now
                      </Button>
                      <Button
                        type="button"
                        id="ContactFormBtn"
                        className="w-100   forecastContactformSubmitbuynowBtn"
                        onClick={(event) => this.Buynow(event)}
                        style={{ borderLeft: "2px solid white" }}
                      >
                        Buy Now
                      </Button>
                    </div>
                    {/*{this.state.emptyField !== '' ?*/}
                    {/*    <p style={{color: 'red'}}>{this.state.emptyField}</p> : null}*/}
                  </Form>
                </div>
              </Col>
              <Col md={6} xs={12} style={{ minHeight: "85vh" }}>
                <div className="colorCode_div">
                  <div
                    className="insideDiv"
                    style={{ backgroundColor: color }}
                  />
                  <p className="color_Name">{colorName}</p>
                  <p className="color_Code">{colorCode}</p>
                </div>
                <div className="bgIndex">
                  <button
                    onClick={this.PrevChangeBg}
                    style={{
                      border: " none",
                      outline: "none",
                      cursor: "pointer",
                      backgroundColor: " transparent",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLongArrowAltLeft}
                      style={{
                        fontSize: "30px",
                        color: "#fff",
                      }}
                    />
                  </button>
                  <span>
                    <p>
                      <span className="indexNum">0{index + 1}</span>
                      <span className="totalindex">/03</span>
                    </p>
                  </span>
                  <button
                    style={{
                      border: " none",
                      backgroundColor: " transparent",
                      cursor: "pointer",
                      outline: "none",
                    }}
                    onClick={this.NextChangeBg}
                  >
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      style={{
                        fontSize: "30px",
                        color: "#fff",
                      }}
                    />
                  </button>
                </div>
              </Col>
            </Row>
          </div>
          <div></div>
        </div>
        <Footer />
      </>
    )
  }
}

export default withRouter(ForecastContactForm)
