import React from "react"
import "./ForecastformPage.css"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"
import Form from "react-bootstrap/Form"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import cogoToast from "cogo-toast"
import client from "../../Apollo/apolloClient"
import {CREATE_FORECAST_FORM_MUTATION} from "../../Apollo/mutations/CreateForecastFormMutation"

class ForecastFormPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Name: "",
      Email: "",
      PhoneNumber: "",
      Profession: "",
      EmailFieldError: "",
      NumberFieldError: "",
      emptyField: "",
    }
  }

  DownloadPdf = () => {
    const { Name, PhoneNumber, Email, Profession } = this.state

    if (Name === "") {
      this.setState({
        emptyField: "fill Name field",
      })
    } else if (PhoneNumber === "") {
      this.setState({
        emptyField: "fill phonenumber field",
      })
    } else if (Email === "") {
      this.setState({
        emptyField: "fill email field",
      })
    } else if (Profession === "") {
      this.setState({
        emptyField: "fill profession field",
      })
    } else {
      this.setState({
        emptyField: "",
      })
      client
        .mutate({
          mutation: CREATE_FORECAST_FORM_MUTATION, // Corrected the property name
          variables: {
            email: Email,
            mobileNumber: PhoneNumber,
            name: Name,
            profession: Profession,
          },
        })
        .then((response) => {
          if (response.data && response.data.downloadPdf) {
            cogoToast.success("We have sent the Forecast to Your Email", {
              position: "top-center",
            })
            this.setState({
              Name: "",
              Email: "",
              PhoneNumber: "",
              Profession: "",
            })
            window.open(
              "https://nipponpaint.co.in/cvt/Color_Vision_Book.pdf",
              "_blank"
            )
          } else {
            cogoToast.error("Please try again", { position: "top-center" })
          }
        })
        .catch((error) => {
          console.error("Error fetching regions list:", error)
          cogoToast.error("Failed to fetch data", { position: "top-center" })
        })
    }
  }

  handleChangeInput = (event) => {
    if (event.target.name === "Name") {
      this.setState({ Name: event.target.value })
    } else if (event.target.name === "PhoneNumber") {
      const reg = /^[1-9]{1}[0-9]{9}$/
      let NumlValid = reg.test(event.target.value)

      let fieldValidationErrors = NumlValid ? "" : "Number is invalid"

      if (event.target.value.length < 10 || event.target.value.length > 10) {
        this.setState({
          NumberFieldError: "Number is invalid",
          PhoneNumber: event.target.value,
        })
      } else {
        this.setState({
          NumberFieldError: fieldValidationErrors,
          PhoneNumber: event.target.value,
        })
      }
    } else if (event.target.name === "Email") {
      let emailValid = event.target.value.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      )
      let fieldValidationErrors = emailValid ? "" : "email is invalid"
      this.setState({
        EmailFieldError: fieldValidationErrors,
        Email: event.target.value,
      })
    } else if (event.target.name === "Profession") {
      this.setState({
        Profession: event.target.value,
      })
    }
  }

  render() {
    return (
      <div className="forecastform text-center">
        <ButtonToolbar>
          <OverlayTrigger
            trigger="click"
            key="bottom"
            placement="bottom"
            ref="overlay"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Content>
                  <div className="FormStyle">
                    <p className="formTitle">
                      Submit Your Details To Download The Trend PDF
                    </p>
                    <Form className="mt-4 mb-4 text-left">
                      <Form.Group controlId="formBasicName" className="mb-4">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="Name"
                          placeholder="John Doe"
                          onChange={(event) => this.handleChangeInput(event)}
                          value={this.state.Name}
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail" className="mb-4">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="john@example.com"
                          onChange={(event) => this.handleChangeInput(event)}
                          name="Email"
                          value={this.state.Email}
                        />
                        {this.state.EmailFieldError !== "" ? (
                          <p style={{ color: "red" }}>
                            {this.state.EmailFieldError}
                          </p>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="formBasicNumber" className="mb-4">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="9876543210"
                          name="PhoneNumber"
                          onChange={(event) => this.handleChangeInput(event)}
                          value={this.state.PhoneNumber}
                        />
                        {this.state.NumberFieldError !== "" ? (
                          <p style={{ color: "red" }}>
                            {this.state.NumberFieldError}
                          </p>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="formBasicName" className="mb-4">
                        <Form.Label>Profession</Form.Label>
                        <Form.Control
                          type="text"
                          name="Profession"
                          placeholder=""
                          onChange={(event) => this.handleChangeInput(event)}
                          value={this.state.Profession}
                        />
                      </Form.Group>
                      <Button
                        type="button"
                        className="w-100 rounded-0  forecastformSubmitBtn  mt-3 mb-3"
                        onClick={(event) => this.DownloadPdf(event)}
                      >
                        DOWNLOAD FOR FREE
                      </Button>
                      {this.state.emptyField !== "" ? (
                        <p style={{ color: "red" }}>{this.state.emptyField}</p>
                      ) : null}
                    </Form>
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <div className="buttonDiv">
              <Button className="forecastformBtn">
                <FontAwesomeIcon
                  icon={faCloudDownloadAlt}
                  style={{ marginRight: "10px" }}
                />
                DOWNLOAD FORECAST 24-25
              </Button>
            </div>
          </OverlayTrigger>
        </ButtonToolbar>
      </div>
    )
  }
}
export default ForecastFormPage
