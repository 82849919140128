import React from "react"
import Container from "react-bootstrap/Container"
import colorvisionLogo from "../../Assets/Images/ColorVision_Logo.svg"
import Modal from "react-bootstrap/Modal"
import $ from "jquery"
import "./Header.css"
import { withRouter } from "react-router"
import cogoToast from "cogo-toast"
import client from "../../Apollo/apolloClient"
import { REGIONS_LIST_QUERY } from "../../Apollo/queries/RegionListQuery"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlayed: false,
      ModalShow: false,
      regionList: "",
    }
  }
  componentDidMount() {
    let self = this

    $(".hamburger").click(function () {
      $(this).toggleClass("is-active")

      self.setState({
        ModalShow: !self.state.ModalShow,
      })
    })

    // Fetch regions list using ApolloClient
    client
      .query({
        query: REGIONS_LIST_QUERY,
      })
      .then((response) => {
        if (response.data && response.data.regionsList) {
          this.setState({ regionList: response.data.regionsList })
        } else {
          cogoToast.error("There is no data", { position: "top-center" })
        }
      })
      .catch((error) => {
        console.error("Error fetching regions list:", error)
        cogoToast.error("Failed to fetch data", { position: "top-center" })
      })
  }

  goThemeListPage(eve, regionid, region) {
    $(".hamburger").removeClass("is-active")
    this.setState({
      ModalShow: !this.state.ModalShow,
    })
    this.props.history.push("/forecasts/" + region + "/" + regionid)
  }
  goIndiaBloomPage() {
    $(".hamburger").removeClass("is-active")
    this.props.history.push("/IndiaBloomPage")
  }
  goToHome() {
    this.props.history.push("/")
  }
  gotoTeamPage() {
    this.props.history.push("/OurTeamPage")
  }
  render() {
    return (
      <div>
        <Container fluid={true} className="p-0">
          <div className="HeaderNav" id="HeaderNav">
            <div className="hamburger pt-2 pb-2 pl-3 pr-3" id="hamburger-1">
              <span className="line" />
              <span className="line" style={{ width: "20px" }} />
              <span className="line" />
            </div>
            <div
              className="Logo pt-2 pb-2 pl-4 pr-4"
              onClick={() => this.goToHome()}
              style={{ cursor: "pointer" }}
            >
              <img src={colorvisionLogo} alt="logo" />
            </div>
          </div>
          <Modal
            show={this.state.ModalShow}
            // onHide={() => setShow(false)}
            // dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            dialogClassName="HeaderModal"
          >
            <Modal.Body className="ModalBg d-flex flex-row text-center align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-sm-center">

                <p
                  className="ModalTitle"
                  onClick={(event) => this.goThemeListPage(event, 1, "youth")}
                >
                  Colors of YOUth
                </p>
                <p
                  className="ModalTitle"
                  onClick={(event) => this.goThemeListPage(event, 2, "north")}
                >
                  Colors of North
                </p>
                <p
                  className="ModalTitle"
                  onClick={(event) => this.goThemeListPage(event, 3, "south")}
                >
                  Colors of South
                </p>
                <p className="ModalTitle" onClick={() => this.gotoTeamPage()}>
                  Key Catalysts
                </p>
                <p
                  className="ModalTitle"
                  onClick={() => {
                    this.props.history.push("/team-color-vision")
                  }}
                >
                  {" "}
                  Team Color Vision
                </p>

                <p>{this.props.aaaa}</p>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    )
  }
}

export default withRouter(Header)
