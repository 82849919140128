import React from "react";
import Media from "react-media";
import {small_screen } from "../../variables";
import ForecastContactForm  from './../forecastConactForm/ForecastContactForm'
import ForecastContactFormMbl from "./../forecastConactForm/ForecastContactFormMbl";
import {withRouter} from "react-router";


class ForecastMainPage  extends React.Component{

    render() {
        return (
            <div>
                <div>
                    <Media query={{maxWidth:small_screen}} render={() =>
                        (
                            <ForecastContactFormMbl />

                        )}
                    />
                    <Media query={{minWidth:small_screen }} render={() =>
                        (
                            <ForecastContactForm />
                        )}
                    />

                </div>
            </div>
        );
    }
}

export default withRouter(ForecastMainPage);
