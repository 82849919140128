import { gql } from '@apollo/client';

export const GET_THEME_LIST_QUERY = gql`
  query ThemelistingPageQuery($regionId: Int!) {
    themesBasedOnRegion(regionId: $regionId) {
      id
      image
      url
      url2
      themeId
      rgb
      themeName
    }
  }
`;