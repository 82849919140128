import React from "react";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";



class MetaWrapper extends React.Component{
    render() {
        const {title, description} = this.props.meta;
        ReactGA.set({
            location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
            title: title
        });

        ReactGA.pageview(window.location.pathname);

        return (
            <div>
                <Helmet
                    title={title}
                    meta={[
                        { name: "description", content: description },
                    ]}

                />
                {this.props.children}

            </div>
        );
    }

}
export default MetaWrapper;
